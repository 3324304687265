import React, { useEffect, useState } from "react";
import { Button, FormControl, Dropdown, DropdownButton } from "react-bootstrap";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import "./admin-reports.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { AdminLandingPage } from "../admin_landing_page.js";
import DatePicker from "react-datepicker";
import html2pdf from "html2pdf.js";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../resubale_components/loader/loader.js";

export const AdminReportPage = () => {
  const [loading, setLoading] = useState(false);
  const [formDataList, setFormDataList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [groupedData, setGroupedData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleClearRows, setToggleClearRows] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [totalForms, setTotalForms] = useState("");
  const [newForms, setNewForms] = useState("");
  const [cancelledForms, setCancelledForms] = useState("");
  const [recountForms, setRecountForms] = useState("");
  const [againstVarianceForms, setAgainstVarianceForms] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (formDataList?.length) {
      const counts = formDataList.reduce(
        (acc, form) => {
          const innerForm = form[0];
          if (innerForm?.formType) {
            switch (innerForm.formType) {
              case "New Form":
                acc.newForms++;
                break;
              case "Recount":
                acc.recountForms++;
                break;
              case "Cancelled/Re-entered":
                acc.cancelledForms++;
                break;
              case "Against Variance":
                acc.againstVarianceForms++;
                break;
              default:
                console.log("Unhandled formType:", innerForm.formType);
                break;
            }
            acc.totalForms++;
          }
          return acc;
        },
        {
          totalForms: 0,
          newForms: 0,
          cancelledForms: 0,
          recountForms: 0,
          againstVarianceForms: 0,
        }
      );

      setTotalForms(counts.totalForms);
      setNewForms(counts.newForms);
      setCancelledForms(counts.cancelledForms);
      setRecountForms(counts.recountForms);
      setAgainstVarianceForms(counts.againstVarianceForms);
    }
  }, [formDataList?.length]);

  const openDB = () => {
    const dbName = `FormDataDB_admin_${localStorage.getItem("username")}`;
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, 2);

      request.onupgradeneeded = (e) => {
        const db = e.target.result;
        if (!db.objectStoreNames.contains("formData")) {
          db.createObjectStore("formData", {
            keyPath: "id",
            autoIncrement: true,
          });
        }
      };

      request.onsuccess = (e) => {
        resolve(e.target.result);
      };

      request.onerror = (e) => {
        reject("IndexedDB error: " + e.target.error);
      };
    });
  };

  const fetchDataFromIndexedDB = () => {
    return new Promise((resolve, reject) => {
      openDB()
        .then((db) => {
          const transaction = db.transaction("formData", "readonly");
          const store = transaction.objectStore("formData");

          const request = store.getAll();
          request.onsuccess = () => {
            resolve(request.result);
          };
          request.onerror = (e) => {
            reject("Failed to fetch data from IndexedDB: " + e.target.error);
          };
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getDataFromIndexedDB = () => {
    fetchDataFromIndexedDB()
      .then((storedData) => {
        setFormDataList(storedData);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const userId = localStorage.getItem("username");
    if (userId) {
      getDataFromIndexedDB(userId);
    }
  }, []);

  const downloadExcel = (data) => {
    const includedFields = [
      "client_signature",
      "auditor_signature",
      "document",
      "images",
      "documents",
      "image",
      "image1",
    ];

    const getFileName = (value, autoEntryNo, prefix = "FI") => {
      if (value && typeof value === "object") {
        if (Array.isArray(value)) {
          return value.map((file) => getFileName(file, autoEntryNo, prefix)); // Process arrays recursively
        }

        if (value instanceof File && "name" in value) {
          const fileExtension = value.name.split(".").pop();
          return `${prefix}_${value.name.split(".")[0]}_${autoEntryNo.slice(
            -8
          )}.${fileExtension}`;
        } else if (typeof value === "string" && value.startsWith("blob:")) {
          return `${prefix}_image_${autoEntryNo.slice(-8)}.webp`; // Default to .webp for blobs
        }
      }
      return value;
    };

    const flattenData = (entry) => {
      const autoEntryNo = entry.auto_entry_no || "";

      const filteredFields = Object.fromEntries(
        Object.entries(entry)
          .filter(([key]) => includedFields.includes(key))
          .map(([key, value]) => {
            if (Array.isArray(value)) {
              // Special handling for images field
              if (key === "images") {
                return [
                  key,
                  value.map((fileObj, index) => {
                    const fileName = getFileName(
                      fileObj.file || fileObj,
                      autoEntryNo,
                      "FI"
                    );
                    return `${index + 1}.${fileName}`;
                  }),
                ];
              }

              // Handling tabledata array
              if (key === "tabledata") {
                return [
                  key,
                  value.map((tableRow) => {
                    const updatedRow = { ...tableRow };

                    // Process image and image1 fields in tabledata
                    if (updatedRow.image) {
                      updatedRow.image = getFileName(
                        updatedRow.image,
                        autoEntryNo,
                        "TI"
                      );
                    }
                    if (updatedRow.image1) {
                      updatedRow.image1 = getFileName(
                        updatedRow.image1,
                        autoEntryNo,
                        "TI"
                      );
                    }

                    return updatedRow;
                  }),
                ];
              }

              // General case for arrays
              return [key, value.map((item) => getFileName(item, autoEntryNo))];
            }

            // Exclude auditor_signature and client_signature from filename transformation
            if (key === "auditor_signature" || key === "client_signature") {
              return [key, value];
            }

            // For non-array fields, apply getFileName with appropriate prefix
            const prefix = key === "image" || key === "image1" ? "TI" : "FI";
            return [key, getFileName(value, autoEntryNo, prefix)];
          })
      );

      return { ...entry, ...filteredFields };
    };

    const flattenedData = data.flatMap((item) =>
      item.value.flatMap((entry) => {
        const cancelReason = entry?.cancelReason || "";
        const otherFields = entry[0] || {}; // Adjust this if the structure is different
        const tableData = otherFields.tabledata || [];

        // Extract auto_entry_no from `entry` directly if available
        const autoEntryNo = otherFields.auto_entry_no.slice(-8) || ""; // Ensure auto_entry_no is fetched from the right place

        const cleanedOtherFields = flattenData(otherFields);

        return tableData.map((tableEntry, index) => {
          const cleanedTableEntry = flattenData(tableEntry);

          // Ensure image and image1 in cleanedTableEntry have auto_entry_no appended
          if (cleanedTableEntry.image) {
            cleanedTableEntry.image = getFileName(
              `${cleanedTableEntry.image}_${autoEntryNo}`
            );
          }

          if (cleanedTableEntry.image1) {
            cleanedTableEntry.image1 = getFileName(
              `${cleanedTableEntry.image1}_${autoEntryNo}`
            );
          }

          // If `images` is an array, join it into a comma-separated string
          if (
            cleanedOtherFields.images &&
            Array.isArray(cleanedOtherFields.images)
          ) {
            cleanedOtherFields.images = cleanedOtherFields.images.join(", ");
          }

          return {
            Date: item.label,
            TableDataIndex: index + 1,
            ...cleanedOtherFields,
            ...cleanedTableEntry,
            cancelReason,
          };
        });
      })
    );

    const ws = XLSX.utils.json_to_sheet(flattenedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "merged_data.xlsx");
  };

  const handleDownloadFolder = (data) => {
    const zip = new JSZip();

    data.value.forEach((entries) => {
      const entry = entries[0];
      const folderName = entry.auto_entry_no;
      const folder = zip.folder(folderName);

      // 1. Prepare Serializable Data for Excel
      const serializableData = { ...entry };

      delete serializableData.files;
      delete serializableData.tabledata;

      serializableData.cancelReason = entries?.cancelReason;

      // Handle `document` field
      if (entry.document) {
        folder.file(
          `${entry.document.name.replace(
            /\.[^/.]+$/,
            ""
          )}_${entry.auto_entry_no.slice(-8)}${entry.document.name.slice(
            entry.document.name.lastIndexOf(".")
          )}`,
          entry.document
        );
        serializableData.document = `${entry.document.name.replace(
          /\.[^/.]+$/,
          ""
        )}_${entry.auto_entry_no.slice(-8)}${entry.document.name.slice(
          entry.document.name.lastIndexOf(".")
        )}`;
      }

      // Download previewPdf
      if (entry?.previewPdf) {
        folder.file(
          `${entry.previewPdf?.name?.replace(
            /\.[^/.]+$/,
            ""
          )}_${entry?.auto_entry_no?.slice(-8)}${entry?.previewPdf?.name?.slice(
            entry?.previewPdf?.name?.lastIndexOf(".")
          )}`,
          entry?.previewPdf
        );
        serializableData.previewPdf = `${entry?.previewPdf?.name?.replace(
          /\.[^/.]+$/,
          ""
        )}_${entry?.auto_entry_no?.slice(-8)}${entry?.previewPdf?.name?.slice(
          entry?.previewPdf?.name?.lastIndexOf(".")
        )}`;
      } else {
      }

      // Handle `client_signature`
      if (entry.client_signature) {
        folder.file(
          `client_${entry.client_signature.name.replace(
            /\.[^/.]+$/,
            ""
          )}_${entry.auto_entry_no.slice(
            -8
          )}${entry.client_signature.name.slice(
            entry.client_signature.name.lastIndexOf(".")
          )}`,
          entry.client_signature
        );
        serializableData.client_signature = `client_${entry.client_signature.name.replace(
          /\.[^/.]+$/,
          ""
        )}_${entry.auto_entry_no.slice(-8)}${entry.client_signature.name.slice(
          entry.client_signature.name.lastIndexOf(".")
        )}`;
      }

      // Handle `auditor_signature`
      if (entry.auditor_signature) {
        folder.file(
          `auditor_${entry.auditor_signature.name.replace(
            /\.[^/.]+$/,
            ""
          )}_${entry.auto_entry_no.slice(
            -8
          )}${entry.auditor_signature.name.slice(
            entry.auditor_signature.name.lastIndexOf(".")
          )}`,
          entry.auditor_signature
        );
        serializableData.auditor_signature = `auditor_${entry.auditor_signature.name.replace(
          /\.[^/.]+$/,
          ""
        )}_${entry.auto_entry_no.slice(-8)}${entry.auditor_signature.name.slice(
          entry.auditor_signature.name.lastIndexOf(".")
        )}`;
      }

      // Handle `images` array
      if (entry.images && Array.isArray(entry.images)) {
        const imageNames = entry.images.map((imgObj, index) => {
          if (imgObj.file) {
            folder.file(
              `${index + 1}.${imgObj.file.name.replace(
                /\.[^/.]+$/,
                ""
              )}_${entry.auto_entry_no.slice(-8)}${imgObj.file.name.slice(
                imgObj.file.name.lastIndexOf(".")
              )}`,
              imgObj.file
            );
            return `${index + 1}.${imgObj.file.name.replace(
              /\.[^/.]+$/,
              ""
            )}_${entry.auto_entry_no.slice(-8)}${imgObj.file.name.slice(
              imgObj.file.name.lastIndexOf(".")
            )}`;
          }
          return `${index + 1}_Unknown`;
        });

        serializableData.images = imageNames.join(", ");
      }

      // Handle `tabledata` array for detailed rows
      let tableDataRows = [];

      if (entry.tabledata && Array.isArray(entry.tabledata)) {
        entry.tabledata.forEach((tableEntry, index) => {
          const tableDataSerializable = { ...tableEntry };

          // Handle `image` field in tabledata
          if (tableEntry.image && tableEntry.image.name) {
            folder.file(
              `${tableEntry.image.name.replace(
                /\.[^/.]+$/,
                ""
              )}_${entry.auto_entry_no.slice(-8)}${tableEntry.image.name.slice(
                tableEntry.image.name.lastIndexOf(".")
              )}`,
              tableEntry.image
            );
            tableDataSerializable.image = `${tableEntry.image.name.replace(
              /\.[^/.]+$/,
              ""
            )}_${entry.auto_entry_no.slice(-8)}${tableEntry.image.name.slice(
              tableEntry.image.name.lastIndexOf(".")
            )}`;
          }

          // Handle `image1` field in tabledata
          if (tableEntry.image1 && tableEntry.image1.name) {
            folder.file(
              `${tableEntry.image1.name.replace(
                /\.[^/.]+$/,
                ""
              )}_${entry.auto_entry_no.slice(-8)}${tableEntry.image1.name.slice(
                tableEntry.image1.name.lastIndexOf(".")
              )}`,
              tableEntry.image1
            );
            tableDataSerializable.image1 = `${tableEntry.image1.name.replace(
              /\.[^/.]+$/,
              ""
            )}_${entry.auto_entry_no.slice(-8)}${tableEntry.image1.name.slice(
              tableEntry.image1.name.lastIndexOf(".")
            )}`;
          }

          // Add serialized tabledata row
          tableDataRows.push({
            ...serializableData, // Parent level data for reference
            ...tableDataSerializable, // Child table data details
          });
        });
      }

      // Add Excel for `tabledata`
      const worksheet = XLSX.utils.json_to_sheet(
        tableDataRows.length > 0 ? tableDataRows : [serializableData]
      );
      const workbook = {
        Sheets: { Sheet1: worksheet },
        SheetNames: ["Sheet1"],
      };
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      folder.file(`Excel_${entry.auto_entry_no.slice(-8)}.xlsx`, excelBuffer);
    });

    // Generate ZIP File
    zip.generateAsync({ type: "blob" }).then((content) => {
      const now = new Date();
      const formattedDate = now.toISOString().split("T")[0]; // YYYY-MM-DD
      const formattedTime = now.toTimeString().split(" ")[0].replace(/:/g, "-"); // HH-MM-SS

      saveAs(content, `${formattedDate}_${formattedTime}.zip`);
    });
  };

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const downloadSelectedExcel = () => {
    if (selectedRows.length > 0) {
      downloadExcel(selectedRows);
      setToggleClearRows(!toggleClearRows);
    } else {
      toast.error("No rows selected for download!");
    }
  };

  const downloadSelectedFolder = () => {
    const getSelectedData =
      selectedRows?.length !== 0 ? selectedRows : filteredData;
    const mergedValues = getSelectedData.reduce((acc, obj) => {
      return acc.concat(obj.value);
    }, []);

    const result = {
      label: new Date().toISOString().split("T")[0],
      value: mergedValues,
    };
    const data = result;
    const zip = new JSZip();

    data.value.forEach((entries) => {
      const entry = entries[0];
      const folderName = entry.auto_entry_no;
      const folder = zip.folder(folderName);

      // 1. Prepare Serializable Data for Excel
      const serializableData = { ...entry };

      delete serializableData.files;
      delete serializableData.tabledata;

      serializableData.cancelReason = entries?.cancelReason;

      // Handle `document` field
      if (entry.document) {
        folder.file(
          `${entry.document.name.replace(
            /\.[^/.]+$/,
            ""
          )}_${entry.auto_entry_no.slice(-8)}${entry.document.name.slice(
            entry.document.name.lastIndexOf(".")
          )}`,
          entry.document
        );
        serializableData.document = `${entry.document.name.replace(
          /\.[^/.]+$/,
          ""
        )}_${entry.auto_entry_no.slice(-8)}${entry.document.name.slice(
          entry.document.name.lastIndexOf(".")
        )}`;
      }

      // Download previewPdf
      if (entry.previewPdf) {
        folder.file(
          `${entry.previewPdf.name.replace(
            /\.[^/.]+$/,
            ""
          )}_${entry.auto_entry_no.slice(-8)}${entry.previewPdf.name.slice(
            entry.previewPdf.name.lastIndexOf(".")
          )}`,
          entry.previewPdf
        );
        serializableData.previewPdf = `${entry.previewPdf.name.replace(
          /\.[^/.]+$/,
          ""
        )}_${entry.auto_entry_no.slice(-8)}${entry.previewPdf.name.slice(
          entry.previewPdf.name.lastIndexOf(".")
        )}`;
      } else {
      }

      // Handle `client_signature`
      if (entry.client_signature) {
        folder.file(
          `client_${entry.client_signature.name.replace(
            /\.[^/.]+$/,
            ""
          )}_${entry.auto_entry_no.slice(
            -8
          )}${entry.client_signature.name.slice(
            entry.client_signature.name.lastIndexOf(".")
          )}`,
          entry.client_signature
        );
        serializableData.client_signature = `client_${entry.client_signature.name.replace(
          /\.[^/.]+$/,
          ""
        )}_${entry.auto_entry_no.slice(-8)}${entry.client_signature.name.slice(
          entry.client_signature.name.lastIndexOf(".")
        )}`;
      }

      // Handle `auditor_signature`
      if (entry.auditor_signature) {
        folder.file(
          `auditor_${entry.auditor_signature.name.replace(
            /\.[^/.]+$/,
            ""
          )}_${entry.auto_entry_no.slice(
            -8
          )}${entry.auditor_signature.name.slice(
            entry.auditor_signature.name.lastIndexOf(".")
          )}`,
          entry.auditor_signature
        );
        serializableData.auditor_signature = `auditor_${entry.auditor_signature.name.replace(
          /\.[^/.]+$/,
          ""
        )}_${entry.auto_entry_no.slice(-8)}${entry.auditor_signature.name.slice(
          entry.auditor_signature.name.lastIndexOf(".")
        )}`;
      }

      // Handle `images` array
      if (entry.images && Array.isArray(entry.images)) {
        const imageNames = entry.images.map((imgObj, index) => {
          if (imgObj.file) {
            folder.file(
              `${index + 1}.${imgObj.file.name.replace(
                /\.[^/.]+$/,
                ""
              )}_${entry.auto_entry_no.slice(-8)}${imgObj.file.name.slice(
                imgObj.file.name.lastIndexOf(".")
              )}`,
              imgObj.file
            );
            return `${index + 1}.${imgObj.file.name.replace(
              /\.[^/.]+$/,
              ""
            )}_${entry.auto_entry_no.slice(-8)}${imgObj.file.name.slice(
              imgObj.file.name.lastIndexOf(".")
            )}`;
          }
          return `${index + 1}_Unknown`;
        });

        serializableData.images = imageNames.join(", ");
      }

      // Handle `tabledata` array for detailed rows
      let tableDataRows = [];

      if (entry.tabledata && Array.isArray(entry.tabledata)) {
        entry.tabledata.forEach((tableEntry, index) => {
          const tableDataSerializable = { ...tableEntry };

          // Handle `image` field in tabledata
          if (tableEntry.image && tableEntry.image.name) {
            folder.file(
              `${tableEntry.image.name.replace(
                /\.[^/.]+$/,
                ""
              )}_${entry.auto_entry_no.slice(-8)}${tableEntry.image.name.slice(
                tableEntry.image.name.lastIndexOf(".")
              )}`,
              tableEntry.image
            );
            tableDataSerializable.image = `${tableEntry.image.name.replace(
              /\.[^/.]+$/,
              ""
            )}_${entry.auto_entry_no.slice(-8)}${tableEntry.image.name.slice(
              tableEntry.image.name.lastIndexOf(".")
            )}`;
          }

          // Handle `image1` field in tabledata
          if (tableEntry.image1 && tableEntry.image1.name) {
            folder.file(
              `${tableEntry.image1.name.replace(
                /\.[^/.]+$/,
                ""
              )}_${entry.auto_entry_no.slice(-8)}${tableEntry.image1.name.slice(
                tableEntry.image1.name.lastIndexOf(".")
              )}`,
              tableEntry.image1
            );
            tableDataSerializable.image1 = `${tableEntry.image1.name.replace(
              /\.[^/.]+$/,
              ""
            )}_${entry.auto_entry_no.slice(-8)}${tableEntry.image1.name.slice(
              tableEntry.image1.name.lastIndexOf(".")
            )}`;
          }

          // Add serialized tabledata row
          tableDataRows.push({
            ...serializableData, // Parent level data for reference
            ...tableDataSerializable, // Child table data details
          });
        });
      }

      // Add Excel for `tabledata`
      const worksheet = XLSX.utils.json_to_sheet(
        tableDataRows.length > 0 ? tableDataRows : [serializableData]
      );
      const workbook = {
        Sheets: { Sheet1: worksheet },
        SheetNames: ["Sheet1"],
      };
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      folder.file(`Excel_${entry.auto_entry_no.slice(-8)}.xlsx`, excelBuffer);
    });

    // Generate ZIP File
    zip.generateAsync({ type: "blob" }).then((content) => {
      const now = new Date();
      const formattedDate = now.toISOString().split("T")[0]; // YYYY-MM-DD
      const formattedTime = now.toTimeString().split(" ")[0].replace(/:/g, "-"); // HH-MM-SS

      saveAs(content, `${formattedDate}_${formattedTime}.zip`);
    });
  };

  const handleDownloadSingle = (row) => {
    downloadExcel([row]);
  };

  const handleNavigate = (e, rowData) => {
    e.preventDefault();
    navigate("/admin-day-report", { state: rowData });
  };

  const filterDataByDate = (data) => {
    return data.filter((row) => {
      const start = new Date(new Date(startDate).setHours(0, 0, 0, 0));
      const end = new Date(new Date(endDate).setHours(0, 0, 0, 0));
      const rowDate = new Date(new Date(row.label).setHours(0, 0, 0, 0));

      const matchesDateRange =
        (!startDate || rowDate >= start) && (!endDate || rowDate <= end);

      return matchesDateRange;
    });
  };

  let filteredData = groupedData?.filter((row) =>
    row.label.includes(searchTerm.toLowerCase())
  );

  if (startDate && endDate) {
    filteredData = filterDataByDate(filteredData);
  }

  function convertBlobToFile(blob, fileName) {
    if (blob && blob.size && fileName) {
      const type =
        fileName.split(".").pop() === "pdf"
          ? "application/pdf"
          : `image/${fileName.split(".").pop()}`;
      return new File([blob], fileName, {
        type: type || "application/octet-stream",
        lastModified: new Date().getTime(),
      });
    } else {
      console.error("Invalid Blob object for conversion:", blob);
      return null;
    }
  }

  // zip upload
    const handleZipFileUpload = async (event) => {
      setLoading(true); 
      const file = event.target.files[0];
      if (!file) return;
  
      event.target.value = null;
  
      try {
        const username = localStorage.getItem("username");
        if (!username) {
          throw new Error("Username is not set in localStorage.");
        }
  
        const dbName = `FormDataDB_admin_${username}`;
        const db = await openDB(dbName, 2, {
          upgrade(db) {
            if (!db.objectStoreNames.contains("formData")) {
              db.createObjectStore("formData", { keyPath: "folderName" });
            }
          },
        });
  
        const zip = new JSZip();
        const zipContent = await zip.loadAsync(file);
        const zipFileName = file.name;
  
        // Check for duplicates before processing
        const tx = db.transaction("formData", "readonly");
        const store = tx.objectStore("formData");
        let isDuplicate = false;
  
        const request = store.openCursor();
        await new Promise((resolve, reject) => {
          request.onsuccess = (event) => {
            const cursor = event.target.result;
            if (cursor) {
              if (
                cursor.value[0].folderName?.toLowerCase() ===
                zipFileName?.toLowerCase()
              ) {
                isDuplicate = true;
                resolve();
              } else {
                cursor.continue();
              }
            } else {
              resolve();
            }
          };
          request.onerror = (event) => {
            reject(event.target.error);
          };
        });
  
        if (isDuplicate) {
          setLoading(false); 
          toast.error(`Duplicate folder detected: ${zipFileName}`);
          return;
        }
  
        const folderData = {};
  
        for (const [relativePath, zipEntry] of Object.entries(zipContent.files)) {
          if (zipEntry.dir) continue; // Skip directories
  
          const fileContent = await zipEntry.async("blob"); // Get file content as Blob
          const folderName = relativePath.split("/")[0]; // Extract folder name
          const fileName = relativePath.split("/").pop(); // Extract file name
          const fileType = fileName.split(".").pop(); // Extract file extension
  
          if (!folderData[folderName]) {
            folderData[folderName] = [];
          }
  
          let parsedData = null;
          if (fileType === "xlsx" || fileType === "xls") {
            // Read Excel file
            const arrayBuffer = await fileContent.arrayBuffer();
            const workbook = XLSX.read(arrayBuffer, { type: "array" });
  
            // Parse the first sheet into JSON
            const sheetName = workbook.SheetNames[0];
            const sheetData = XLSX.utils.sheet_to_json(
              workbook.Sheets[sheetName]
            );
            parsedData = sheetData;
          }
  
          folderData[folderName].push({
            fileName,
            content:
              fileType === "xlsx" || fileType === "xls"
                ? parsedData
                : fileContent,
            type: fileType,
          });
        }
  
        const formattedData = Object.entries(folderData).map(
          ([folderName, files]) => ({
            folderName: zipFileName,
            files,
          })
        );
  
        const flattened = [];
        const processedAutoEntryNo = new Set(); // To track processed auto_entry_no values
  
        formattedData?.forEach((entry, index) => {
          // console.log(`Processing entry #${index + 1}:`, entry);
  
          const { auto_entry_no, folderName, files, update_senior_remarks } =
            entry;
          if (files && files.length > 0) {
            // console.log(`Entry #${index + 1} has files, processing...`);
  
            const getData = files
              .filter((d) => d.type === "xlsx")
              .map((a) => a.content)[0]
              ?.map((c) => ({
                auto_entry_no, // Use auto_entry_no here
                folderName,
                cancelReason: c.cancelReason,
                physical_count_date: c.physical_count_date,
                sap_code_or_item_no: c.sap_code_or_item_no,
                auto_entry_no: c.auto_entry_no,
                description: c.description,
                client: c.client,
                client_sign_name: c.client_sign_name,
                client_team_name: c.client_team_name,
                compartment_name: c.compartment_name,
                condition: c.condition,
                aisle_name: c.aisle_name,
                auditor_sign_name: c.auditor_sign_name,
                department_name: c.department_name,
                floor: c.floor,
                formType: c.formType,
                item_group_name: c.item_group_name,
                item_sub_group_2: c.item_sub_group_2,
                item_sub_group_3: c.item_sub_group_3,
                location: c.location,
                mixbox: c.mixbox,
                nsi: c.nsi,
                storage_name: c.storage_name,
                team_name: c.team_name,
                total_qty: c.total_qty,
                previous_aen: c.previous_aen,
                auditor_signature: null,
                client_signature: null,
                document: null,
                images: [],
              }));
  
            // Process additional table data for the specific file
            const getTableData = files
              .filter((d) => d.type === "xlsx")
              .map((a) => a.content)[0]
              ?.map((c) => {
                const tiImages = files
                  .filter((file) => file?.fileName?.toLowerCase().includes("ti"))
                  .map((file) => convertBlobToFile(file.content, file.fileName));
  
                return {
                  uom_physical: c.uom_physical,
                  batch_no: c.batch_no,
                  auditor_observation: c.auditor_observation,
                  auditor_observation_class: c.auditor_observation_class,
                  expiry_date: c.expiry_date,
                  mansa_remarks: c.mansa_remarks,
                  more_info_type_1: c.more_info_type_1,
                  more_info_type_2: c.more_info_type_2,
                  more_info_type_3: c.more_info_type_3,
                  more_information: c.more_information,
                  package_no: c.package_no,
                  physical_qty: c.physical_qty,
                  store_name: c.store_name,
                  senior_remarks: c.senior_remarks,
                  image: tiImages[0] || null,
                  image1: tiImages[1] || null,
                };
              });
  
            // Gather file data and image files
            const fileData = files.map((file) => ({
              fileName: file?.fileName || "NA",
              type: file.type || "NA",
              content: file.content || null,
            }));
  
            const imageFiles = files
              .filter((file) => file?.fileName.includes("FI"))
              .map((file) => ({
                file: convertBlobToFile(file.content, file.fileName),
                image: URL.createObjectURL(file.content),
              }));
  
            if (getData && getData.length > 0) {
              getData.forEach((dataItem) => {
                if (!processedAutoEntryNo.has(dataItem.auto_entry_no)) {
                  // console.log(
                  //   `Processing data item with auto_entry_no: ${dataItem.auto_entry_no}`
                  // );
  
                  // Process signature and document files for each entry
                  const auditorSignatureFile = files.find((file) =>
                    file?.fileName?.includes("auditor_signature")
                  );
                  const clientSignatureFile = files.find((file) =>
                    file?.fileName?.includes("client_signature")
                  );
                  const documentFile = files.find((file) =>
                    file?.fileName?.includes("document")
                  );
  
                  // Assign File objects for signatures and document
                  dataItem.auditor_signature = auditorSignatureFile
                    ? convertBlobToFile(
                        auditorSignatureFile.content,
                        auditorSignatureFile.fileName
                      )
                    : null;
  
                  dataItem.client_signature = clientSignatureFile
                    ? convertBlobToFile(
                        clientSignatureFile.content,
                        clientSignatureFile.fileName
                      )
                    : null;
  
                  dataItem.document = documentFile
                    ? convertBlobToFile(
                        documentFile.content,
                        documentFile.fileName
                      )
                    : null;
  
                  // Merge image files into images array
                  dataItem.images = imageFiles;
  
                  // Add to flattened array
                  flattened.push({
                    ...dataItem,
                    files: fileData,
                    tabledata: getTableData,
                  });
  
                  processedAutoEntryNo.add(dataItem.auto_entry_no);
                } else {
                  // console.log(`Skipping data item with auto_entry_no: ${dataItem.auto_entry_no} as it was already processed.`);
                }
              });
            } else {
              // console.log(`No getData for entry #${index + 1}, skipping.`);
            }
          } else {
            // console.log(`Entry #${index + 1} has no files, skipping...`);
          }
        });
  
        if (flattened?.length !== 0 && formDataList?.length !== 0) {
          const uniqueValues = flattened.filter(
            (c) =>
              !formDataList.some((d) => d[0].auto_entry_no === c.auto_entry_no)
          );
  
          const saveStructureDataToIndexedDB = async (
            db,
            duplicateValueFilter
          ) => {
            try {
              const tx = db.transaction("formData", "readwrite");
              const store = tx.objectStore("formData");
  
              for (const entry of duplicateValueFilter) {
                const formattedEntry = {
                  0: entry,
                };
                await store.put(formattedEntry);
              }
  
              await tx.done;
              if (uniqueValues?.length !== 0) {
                toast.success("File uploaded successfully!");
              } else {
                toast.error("File already existed!");
              }
  
              getDataFromIndexedDB();
            } catch (error) {
              toast.error("Error saving file:", error.message);
            }
          };
          // Save structureData explicitly
          await saveStructureDataToIndexedDB(db, uniqueValues);
        } else {
          const saveStructureDataToIndexedDB = async (db, flattened) => {
            try {
              const tx = db.transaction("formData", "readwrite");
              const store = tx.objectStore("formData");
  
              for (const entry of flattened) {
                const formattedEntry = {
                  0: entry,
                };
                await store.put(formattedEntry);
              }
  
              await tx.done;
              setLoading(false); 
              toast.success("File uploaded successfully!");
              getDataFromIndexedDB();
            } catch (error) {
              setLoading(false); 
              toast.error("Error saving file:", error.message);
            }
          };
          // Save structureData explicitly
          await saveStructureDataToIndexedDB(db, flattened);
        }
      } catch (error) {
        toast.error("Error processing ZIP file");
        setLoading(false); 
      }
    };

  const filterFormWithDate = () => {
    const grouped = formDataList?.reduce((acc, data) => {
      const date = data[0]?.physical_count_date;

      if (!acc[date]) {
        acc[date] = [];
      }

      acc[date].push(data);
      return acc;
    }, {});

    if (grouped) {
      const groupedArray = Object.entries(grouped).map(([date, data]) => ({
        label: date,
        value: data,
      }));
      setGroupedData(groupedArray);
    }
  };
  useEffect(() => {
    if (formDataList) {
      filterFormWithDate();
    }
  }, [formDataList?.length]);

  const columns = [
    { name: "Sr.No", selector: (row, index) => index + 1 },
    { name: "Date", selector: (row) => row.label, sortable: true },
    {
      name: "Total Forms",
      selector: (row) => row.value.length,
      sortable: true,
    },
    {
      name: "Total Quantity",
      selector: (row) => {
        return row.value.reduce((sum, item) => {
          const qty = item[0]?.total_qty;
          return sum + (isNaN(Number(qty)) ? 0 : Number(qty));
        }, 0);
      },
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <div className="action-btn-group mx-1">
            <a
              className="ff-plus-jakarta btn btn-view"
              type="button"
              onClick={(e) => handleNavigate(e, row)}
            >
              <i className="bi bi-eye"></i> View
            </a>
          </div>
          <div>
            <DropdownButton
              title={<i className="bi bi-download"></i>}
              className="p-0 ff-plus-jakarta btn btn-download-outer"
            >
              <Dropdown.Item onClick={() => handleDownloadSingle(row)}>
                Excel
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleDownloadFolder(row)}>
                Folder
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <AdminLandingPage />
      <div className="reports-wrapper">
        <div className="container">
          <div className="top-header">
            <h1 className="ff-plus-jakarta page-title">List of items</h1>
            <Loader show={loading} />
            <div
              className="btn filter-button mx-3"
              onClick={() => document.getElementById("file-upload").click()}
            >
              <i className="bi bi-upload mx-1"></i> Zip
              <input
                type="file"
                id="file-upload"
                accept=".zip"
                onChange={(event) => handleZipFileUpload(event)}
                style={{ display: "none" }}
              />
            </div>

            <div>
              <DropdownButton
                title={<i className="bi bi-download"></i>}
                className="p-0 ff-plus-jakarta btn btn-download-outer mx-3"
              >
                <Dropdown.Item onClick={downloadSelectedExcel}>
                  Excel
                </Dropdown.Item>
                <Dropdown.Item onClick={downloadSelectedFolder}>
                  Folder
                </Dropdown.Item>
              </DropdownButton>
            </div>

            <div className="date-picker-wrapper">
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => setDateRange(update)}
                isClearable={true}
                placeholderText="Select date range"
              />
              <i class="bi bi-calendar"></i>
            </div>
          </div>

          <div className="count-container">
            <span className="label">Total Forms:</span>
            <span className="value">{totalForms ? totalForms : 0}</span>

            <span className="label"> | New Forms:</span>
            <span className="value">{newForms ? newForms : 0}</span>

            <span className="label"> | Recount:</span>
            <span className="value">{recountForms ? recountForms : 0}</span>

            <span className="label"> | Cancelled Forms:</span>
            <span className="value">{cancelledForms ? cancelledForms : 0}</span>

            <span className="label"> | Against Variance:</span>
            <span className="value">
              {againstVarianceForms ? againstVarianceForms : 0}
            </span>
          </div>

          <div className="ff-plus-jakarta w-100">
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
              selectableRows
              onSelectedRowsChange={handleRowSelected}
              clearSelectedRows={toggleClearRows}
            />
            <hr />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
