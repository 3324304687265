import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  Toast,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import "./admin-reports.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import "./admin-reports.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AdminLandingPage } from "../admin_landing_page.js";
import { toast, ToastContainer } from "react-toastify";

export const AdminDayReport = () => {
  const navigate = useNavigate();
  const [flattenedData, setFlattenedData] = useState([]);
  const location = useLocation();
  const dataList = location?.state;

  const [showTableImage, setShowTableImage] = useState(false);
  const [currentTableImage, setCurrentTableImage] = useState(null);
  const [showImageForRow, setShowImageForRow] = useState(null);
  const [showTableImage1, setShowTableImage1] = useState(false);
  const [currentTableImage1, setCurrentTableImage1] = useState(null);
  const [showImageForRow1, setShowImageForRow1] = useState(null);

  const [activeAuditorSigns, setActiveAuditorSigns] = useState([]);
  const [activeClientSigns, setActiveClientSigns] = useState([]);
  const [activeRowImages, setActiveRowImages] = useState([]);
  const [toggleClearRows, setToggleClearRows] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [selectedOption, setSelectedOption] = useState("");
  const [searchFields, setSearchFields] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [popupState, setPopupState] = useState({
    visible: false,
    inputValue: "",
  });

  const [popupSeniorRemarks, setPopupSeniorRemarks] = useState({
    visible: false,
    inputValue: "",
  });

  const [column] = useState([
    { key: "auto_entry_no", label: "Auto Entry No" },
    { key: "total_qty", label: "Total Qty" },
    { key: "department_name", label: "Department Name" },
    { key: "storage_name", label: "Storage Name" },
    { key: "description", label: "description" },
    { key: "formType", label: "formType" },
    { key: "sap_code_or_item_no", label: "Sap Code" },
    { key: "location", label: "location" },
  ]);

  const showClientSignature = (e, autoEntryNo) => {
    e.preventDefault();

    const matchingEntry = dataList?.value
      .map((d) => d[0])
      .find((entry) => entry.auto_entry_no === autoEntryNo);

    if (matchingEntry && matchingEntry.client_signature) {
      const file = matchingEntry.client_signature;

      // Check if the file is a Blob or File
      if (file instanceof Blob || file instanceof File) {
        // Create an object URL for the image file
        const imageUrl = URL.createObjectURL(file);

        // Add the auto_entry_no's signature to the state
        setActiveClientSigns((prev) => [
          ...prev,
          { autoEntryNo, signUrl: imageUrl },
        ]);
      } else {
        console.error("Invalid file format. Expected a Blob or File object.");
      }
    } else {
      console.error(`No signature found for auto_entry_no: ${autoEntryNo}.`);
    }
  };

  const downloadClientSign = (e, row) => {
    e.preventDefault();

    const signUrl = e.target
      .closest(".signature-container")
      .querySelector("img").src;

    if (signUrl) {
      // Create an invisible link element to trigger the download
      const link = document.createElement("a");
      link.href = signUrl;
      link.download = `client_signature_${row.auto_entry_no.slice(-8)}.png`; // You can customize the file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Signature URL not found");
    }
  };

  const downloadAuditorSign = (e, row) => {
    e.preventDefault(); // Prevent any parent click handlers from being triggered

    const signUrl = e.target
      .closest(".signature-container")
      .querySelector("img").src;

    if (signUrl) {
      // Create an invisible link element to trigger the download
      const link = document.createElement("a");
      link.href = signUrl;
      link.download = `auditor_signature_${row.auto_entry_no.slice(-8)}.png`; // You can customize the file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Signature URL not found");
    }
  };

  const showAuditorSignature = (e, autoEntryNo) => {
    e.preventDefault();
    const matchingEntry = dataList?.value
      .map((d) => d[0])
      .find((entry) => entry.auto_entry_no === autoEntryNo);

    if (matchingEntry && matchingEntry.auditor_signature) {
      const file = matchingEntry.auditor_signature;

      // Check if the file is a Blob or File
      if (file instanceof Blob || file instanceof File) {
        // Create an object URL for the image file
        const imageUrl = URL.createObjectURL(file);

        // Add the auto_entry_no's signature to the state
        setActiveAuditorSigns((prev) => [
          ...prev,
          { autoEntryNo, signUrl: imageUrl },
        ]);
      } else {
        console.error("Invalid file format. Expected a Blob or File object.");
      }
    } else {
      console.error(`No signature found for auto_entry_no: ${autoEntryNo}.`);
    }
  };

  const handleImages = (e, autoEntryNo) => {
    e.preventDefault();
    const matchingEntries = dataList?.value
      .map((d) => d[0])
      .filter((entry) => entry.auto_entry_no === autoEntryNo);

    if (matchingEntries.length > 0) {
      const ImageList = matchingEntries.map((entry) => entry?.images || []);

      const imageUrls = ImageList.flat()
        .map((imageObj) => {
          if (imageObj) {
            // Check if there is a valid 'file' (File or Blob)
            if (
              imageObj.file &&
              (imageObj.file instanceof Blob || imageObj.file instanceof File)
            ) {
              return URL.createObjectURL(imageObj.file); // Create URL for Blob or File
            }
            // If there is a valid 'image' (Blob URL), use it directly
            else if (imageObj.image) {
              return imageObj.image; // Use the existing URL
            }
          }
          return null; // Handle invalid cases
        })
        .filter((url) => url !== null); // Remove null values

      if (imageUrls.length > 0) {
        // Add the auto_entry_no's images to the activeRowImages state
        setActiveRowImages((prev) => [
          ...prev,
          { autoEntryNo, images: imageUrls },
        ]);
      } else {
        console.error("No valid images found for this auto_entry_no.");
      }
    } else {
      console.error(
        `No matching entry found for auto_entry_no: ${autoEntryNo}.`
      );
    }
  };

  const downloadAllImages = (e, row) => {
    e.stopPropagation();

    const matchingEntry = dataList?.value
      ?.map((d) => d[0])
      ?.find((entry) => entry.auto_entry_no === row.auto_entry_no)?.images;

    const matchingFiles = matchingEntry.map((d) => d.file);

    if (matchingFiles && matchingFiles.length > 0) {
      matchingFiles.forEach((file, index) => {
        // Check if the file is a Blob or File object
        if (file instanceof Blob || file instanceof File) {
          // Create an object URL for the file
          const blobUrl = URL.createObjectURL(file);
          const link = document.createElement("a");
          link.href = blobUrl;

          // Generate a unique file name
          const nameWithoutExtension = file.name.split(".").shift();
          const fileExtension = file.name.split(".").pop();
          link.download = `${nameWithoutExtension}_${row.auto_entry_no.slice(
            -8
          )}.${fileExtension}`;

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // Revoke the Blob URL to free memory
          URL.revokeObjectURL(blobUrl);
        } else {
          console.error(`Invalid file format for file: ${file.name}`);
        }
      });
    } else {
      console.error("No matching files found for the provided row.");
    }
  };

  const downloadTableImage = (e, row) => {
    e.stopPropagation();

    const imageUrl = currentTableImage;

    if (imageUrl) {
      try {
        // Create an invisible link element to trigger the download
        const link = document.createElement("a");
        link.href = imageUrl;

        // Extract the relevant part for the filename from imageUrl (for example, slice a unique identifier)
        link.download = `${imageUrl.slice(20, 30)}_${row.auto_entry_no.slice(
          -8
        )}`;
        const data = row.image;
        // Extract the file type (part after the last '.' and before '_')
        const fileType = data.substring(
          data.lastIndexOf(".") + 1,
          data.indexOf("_")
        );

        // Construct the filename properly with the file extension
        link.download = `${imageUrl.slice(20, 30)}_${row.auto_entry_no.slice(
          -8
        )}.${fileType}`;

        // Trigger the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error during download process:", error);
      }
    } else {
      console.error("Table Image URL not found");
    }
  };

  const handleTableImage = (e, index, type = "image") => {
    e.preventDefault();

    const tabledata = dataList?.value.map((d) => d[0]?.tabledata).flat();
    const files = dataList?.value.map((d) => d[0]?.files).flat();
    const tableImage = tabledata[index]?.[type];

    if (tableImage) {
      if (tableImage instanceof Blob || tableImage instanceof File) {
        const imageUrl = URL.createObjectURL(tableImage);
        if (type === "image") {
          setCurrentTableImage(imageUrl);
        } else if (type === "image1") {
          setCurrentTableImage1(imageUrl);
        }
        setShowImageForRow(index);
        setShowTableImage(true);
      } else if (typeof tableImage === "string") {
        if (type === "image") {
          setCurrentTableImage(tableImage);
        } else if (type === "image1") {
          setCurrentTableImage1(tableImage);
        }
        setShowImageForRow(index);
        setShowTableImage(true);
      } else {
        console.error(
          "Invalid file format. Expected a Blob, File, or URL string."
        );
      }
    } else if (files) {
      const tableFileImage = files?.find(
        (file) =>
          file.fileName.includes("TI") &&
          file.type === (type === "image" ? "png" : "jpg")
      );

      if (tableFileImage) {
        const imageUrl = URL.createObjectURL(tableFileImage.content);
        if (type === "image") {
          setCurrentTableImage(imageUrl);
        } else if (type === "image1") {
          setCurrentTableImage1(imageUrl);
        }
        setShowImageForRow(index);
        setShowTableImage(true);
      } else {
        console.error(`No ${type} found with 'TI' in the name for this row.`);
      }
    } else {
      console.error(`No ${type} found for this row.`);
    }
  };

  const closeTableImage = () => {
    setShowTableImage(false);
    setCurrentTableImage(null);
    setShowImageForRow(null);
  };

  const handleTableImage1 = (e, index) => {
    e.preventDefault();

    const tabledata = dataList?.value.map((d) => d[0]?.tabledata).flat();
    const files = dataList?.value.map((d) => d[0]?.files).flat();
    const tableImage = tabledata[index]?.image1;

    if (tableImage) {
      if (tableImage instanceof Blob || tableImage instanceof File) {
        const imageUrl = URL.createObjectURL(tableImage);
        setCurrentTableImage1(imageUrl);
        setShowImageForRow1(index);
        setShowTableImage1(true);
      } else if (typeof tableImage === "string") {
        setCurrentTableImage1(tableImage);
        setShowImageForRow1(index);
        setShowTableImage1(true);
      } else {
        console.error(
          "Invalid file format. Expected a Blob, File, or URL string."
        );
      }
    } else if (files) {
      const tableFileImage = files.find(
        (file) => file.fileName.includes("TI") && file.type === "jpg"
      );

      if (tableFileImage) {
        const imageUrl = URL.createObjectURL(tableFileImage.content);
        setCurrentTableImage1(imageUrl);
        setShowImageForRow1(index);
        setShowTableImage1(true);
      } else {
        console.error("No image1 found with 'TI' in the name for this row.");
      }
    } else {
      console.error("No image1 found for this row.");
    }
  };

  const downloadTableImage1 = (e, row) => {
    e.stopPropagation();

    const imageUrl = currentTableImage1;

    if (imageUrl) {
      try {
        // Create an invisible link element to trigger the download
        const link = document.createElement("a");
        link.href = imageUrl;

        // Extract the relevant part for the filename from imageUrl (for example, slice a unique identifier)
        link.download = `${imageUrl.slice(20, 30)}_${row.auto_entry_no.slice(
          -8
        )}`;

        // Extract the image name and file extension from row.image1
        const data = row.image1;

        // Extract the file type (part after the last '.' and before '_')
        const fileType = data.substring(
          data.lastIndexOf(".") + 1,
          data.indexOf("_")
        );

        // Construct the filename properly with the file extension
        link.download = `${imageUrl.slice(20, 30)}_${row.auto_entry_no.slice(
          -8
        )}.${fileType}`;

        // Trigger the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error during download process:", error);
      }
    } else {
      console.error("Table Image1 URL not found");
    }
  };

  const closeTableImage1 = () => {
    setShowTableImage1(false);
    setCurrentTableImage1(null);
    setShowImageForRow1(null);
  };

  const handleShowPdf = (e, autoEntryNo) => {
    e.preventDefault();

    // Find the matching entry based on auto_entry_no
    const matchingEntry = dataList?.value
      .map((d) => d[0])
      .find((entry) => entry.auto_entry_no === autoEntryNo);

    if (matchingEntry && matchingEntry.document) {
      const pdfFile = matchingEntry.document;

      // Check if the document is a valid Blob or File
      if (pdfFile instanceof Blob || pdfFile instanceof File) {
        // Create an object URL for the PDF file
        const pdfUrl = URL.createObjectURL(pdfFile);

        // Open the PDF in a new tab
        window.open(pdfUrl, "_blank");
      } else {
        console.error("Invalid file format. Expected a Blob or File object.");
      }
    } else {
      console.error(`No document found for auto_entry_no: ${autoEntryNo}.`);
    }
  };

  useEffect(() => {
    if (dataList && dataList?.value) {
      const data = dataList?.value || [];

      const flattened = data.flatMap((row, index) => {
        const baseData = {
          srNo: index + 1,
          formType: row[0]?.formType || "NA",
          cancelReason: row?.cancelReason ? row?.cancelReason  : row[0]?.cancelReason ,
          physical_count_date: row[0]?.physical_count_date || "NA",
          auto_entry_no: row[0]?.auto_entry_no || "NA",
          client: row[0].client || "NA",
          sap_code_or_item_no: row[0].sap_code_or_item_no || "NA",
          nsi: row[0].nsi || "NA",
          description: row[0].description || "NA",
          item_group_name: row[0].item_group_name || "NA",
          item_sub_group_2: row[0].item_sub_group_2 || "NA",
          item_sub_group_3: row[0].item_sub_group_3 || "NA",
          client_sign_name: row[0]?.client_sign_name || "NA",
          auditor_sign_name: row[0]?.auditor_sign_name || "NA",
          client_signature:
            `${row[0]?.client_signature?.name}_${row[0].auto_entry_no.slice(
              -8
            )}` || "NA",
          auditor_signature:
            `${row[0]?.auditor_signature?.name}_${row[0].auto_entry_no.slice(
              -8
            )}` || "NA",
          aisle_name: row[0].aisle_name || "NA",
          images:
            row[0]?.images && row[0]?.images?.length > 0
              ? row[0]?.images
                  .map(
                    (image, idx) =>
                      `${idx + 1}. ${
                        image?.file?.name
                      }_${row[0].auto_entry_no.slice(-8)}` || "NA"
                  )
                  .join(", ")
              : "NA",
          floor: row[0].floor || "NA",
          compartment_name: row[0].compartment_name || "NA",
          department_name: row[0].department_name || "NA",
          mixbox: row[0].mixbox || "NA",
          storage_name: row[0].storage_name || "NA",
          location: row[0].location || "NA",
          client_team_name: row[0]?.client_team_name || "NA",
          team_name: row[0]?.team_name || "NA",
          document:
            `${row[0].document?.name}_${row[0].auto_entry_no.slice(-8)}` ||
            "NA",
          total_qty: row[0].total_qty || "NA",
        };

        if (row[0].tabledata && Array.isArray(row[0].tabledata)) {
          return row[0].tabledata.map((tableItem) => ({
            ...baseData,
            package_no: tableItem.package_no || "NA",
            physical_qty: tableItem.physical_qty || "NA",
            uom_physical: tableItem.uom_physical || "NA",
            batch_no: tableItem.batch_no || "NA",
            expiry_date: tableItem.expiry_date || "NA",
            condition: tableItem.condition || "NA",
            auditor_observation: tableItem.auditor_observation || "NA",
            auditor_observation_class:
              tableItem.auditor_observation_class || "NA",
            image:
              `${tableItem?.image?.name}_${row[0].auto_entry_no.slice(-8)}` ||
              "NA",
            image1:
              `${tableItem?.image1?.name}_${row[0].auto_entry_no.slice(-8)}` ||
              "NA",
            mansa_remarks: tableItem.mansa_remarks || "NA",
            senior_remarks: tableItem?.senior_remarks ? tableItem?.senior_remarks : row?.update_senior_remarks,
            more_info_type_1: tableItem.more_info_type_1 || "NA",
            more_info_type_2: tableItem.more_info_type_2 || "NA",
            more_info_type_3: tableItem.more_info_type_3 || "NA",
          }));
        }

        return [baseData];
      });

      setFlattenedData(flattened);
    }
  }, [dataList]);

  const handleInputChange = (event) => {
    setPopupState((prevState) => ({
      ...prevState,
      inputValue: event.target.value,
    }));
  };

  const handleCancelClick = (row) => {
    setPopupState({
      visible: true,
      inputValue: "",
      row,
    });
  };

  const handleCancel = () => {
    setPopupState({ visible: false, inputValue: "", row: null });
  };

  const handleConfirm = async () => {
    const input = popupState?.inputValue;
    const row = popupState?.row;

    const dbName = `FormDataDB_admin_${localStorage.getItem("username")}`;
    const dbRequest = indexedDB.open(dbName, 2);

    dbRequest.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(["formData"], "readwrite");
      const objectStore = transaction.objectStore("formData");

      const rowId = row.auto_entry_no;
      const filterRow = dataList.value.filter(
        (dataItem) => dataItem[0].auto_entry_no === rowId
      );

      if (!rowId) {
        console.error("Row ID is invalid:", rowId);
        return;
      }

      if (filterRow) {
        const getStoreId = filterRow?.map((d) => d.id)[0];
        const getRowAutoEntryNo = filterRow?.map((d) => d[0].auto_entry_no)[0];
        const getRequest = objectStore.get(getStoreId);

        getRequest.onsuccess = (event) => {
          const existingObject = event.target.result;

          if (existingObject) {
            existingObject.cancelReason = input;

            const updateRequest = objectStore.put(existingObject);

            if (getRowAutoEntryNo) {
              updateRequest.onsuccess = () => {
                setFlattenedData((prevData) =>
                  prevData.map((item) =>
                    item.auto_entry_no === getRowAutoEntryNo
                      ? { ...item, cancelReason: input }
                      : item
                  )
                );
              };
              toast.success("Data successfully updated  in IndexedDB");
              setPopupState({
                visible: false,
                inputValue: "",
                row,
              });
            }

            updateRequest.onerror = (error) => {
              toast.error("Error updating object in IndexedDB:");
            };
          } else {
            console.error("No object found for ID:", rowId);
          }
        };

        getRequest.onerror = (error) => {
          console.error(
            "Error retrieving object from IndexedDB:",
            error.target.error
          );
        };
      }

      dbRequest.onerror = (error) => {
        console.error("Error opening IndexedDB:", error.target.error);
      };
    };
  };

  const handleRemarksButtonClick = (row) => {
    setPopupSeniorRemarks({
      visible: true,
      inputValue: "",
      row,
    });
  };


  const handleSeniorRemarksInput = (event) => {
    setPopupSeniorRemarks((prevState) => ({
      ...prevState,
      inputValue: event.target.value,
    }));
  };
  const handleCancelRemarks = () => {
    setPopupSeniorRemarks({ visible: false, inputValue: "", row: null });
  };

  const handleConfirmRemarks = async () => {
    const input = popupSeniorRemarks?.inputValue;
    const row = popupSeniorRemarks?.row;

    const dbName = `FormDataDB_admin_${localStorage.getItem("username")}`;
    const dbRequest = indexedDB.open(dbName, 2);

    dbRequest.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(["formData"], "readwrite");
      const objectStore = transaction.objectStore("formData");

      const rowId = row.auto_entry_no;
      const filterRow = dataList.value.filter(
        (dataItem) => dataItem[0].auto_entry_no === rowId
      );

      if (!rowId) {
        console.error("Row ID is invalid:", rowId);
        return;
      }

      if (filterRow) {
        const getStoreId = filterRow?.map((d) => d.id)[0];
        const getRowAutoEntryNo = filterRow?.map((d) => d[0].auto_entry_no)[0];
        const getRequest = objectStore.get(getStoreId);

        getRequest.onsuccess = (event) => {
          const existingObject = event.target.result;

          if (existingObject) {
            existingObject.update_senior_remarks = input;

            const updateRequest = objectStore.put(existingObject);

            if (getRowAutoEntryNo) {
              updateRequest.onsuccess = () => {
                setFlattenedData((prevData) =>
                  prevData.map((item) =>
                    item.auto_entry_no === getRowAutoEntryNo
                      ? { ...item, update_senior_remarks: input }
                      : item
                  )
                );
              };
              toast.success("Data successfully updated  in IndexedDB");
              setPopupSeniorRemarks({
                visible: false,
                inputValue: input,
                row,
              });
            }

            updateRequest.onerror = (error) => {
              toast.error("Error updating object in IndexedDB:");
            };
          } else {
            console.error("No object found for ID:", rowId);
          }
        };

        getRequest.onerror = (error) => {
          console.error(
            "Error retrieving object from IndexedDB:",
            error.target.error
          );
        };
      }

      dbRequest.onerror = (error) => {
        console.error("Error opening IndexedDB:", error.target.error);
      };
    };
  };

  const downloadExcel = (data) => {
    const aen = data.map((d) => d.auto_entry_no);

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "merged_data.xlsx");
  };

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handleDownloadSelected = () => {
    if (selectedRows.length > 0) {
      downloadExcel(selectedRows);
      setToggleClearRows(!toggleClearRows);
    } else {
      alert("No rows selected for download!");
    }
  };

  const columns = [
    { name: "Sr. No.", selector: (row) => row.srNo, sortable: true },
    {
      name: "Form Type",
      selector: (row) =>  row.formType === "New Form" ? row.formType  :  `${row.formType} (${row?.previous_aen})` ,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.physical_count_date,
      sortable: true,
    },
    {
      name: "Auto Entry No",
      selector: (row) => row.auto_entry_no,
      sortable: true,
    },
    { name: "Client Name", selector: (row) => row.client, sortable: true },
    {
      name: "SAP Code",
      selector: (row) => row.sap_code_or_item_no,
      sortable: true,
    },
    { name: "NSI", selector: (row) => row.nsi, sortable: true },
    {
      name: "Item Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Item Group",
      selector: (row) => row.item_group_name,
      sortable: true,
    },
    {
      name: "Item Sub Group 2",
      selector: (row) => row.item_sub_group_2,
      sortable: true,
    },
    {
      name: "Item Sub Group 3",
      selector: (row) => row.item_sub_group_3,
      sortable: true,
    },
    { name: "Aisle Name", selector: (row) => row.aisle_name, sortable: true },
    { name: "Floor", selector: (row) => row.floor, sortable: true },
    {
      name: "Compartment Name",
      selector: (row) => row.compartment_name,
      sortable: true,
    },
    {
      name: "Department Name",
      selector: (row) => row.department_name,
      sortable: true,
    },
    { name: "Mixbox", selector: (row) => row.mixbox, sortable: true },
    {
      name: "Storage Name",
      selector: (row) => row.storage_name,
      sortable: true,
    },
    { name: "Location", selector: (row) => row.location, sortable: true },
    {
      name: "Client Team",
      selector: (row) => row.client_team_name,
      sortable: true,
    },
    { name: "Team Name", selector: (row) => row.team_name, sortable: true },
    {
      name: "Total Quantity",
      selector: (row) => row.total_qty,
      sortable: true,
    },
    { name: "Package No", selector: (row) => row.package_no, sortable: true },
    {
      name: "Physical Quantity",
      selector: (row) => row.physical_qty,
      sortable: true,
    },
    { name: "UOM", selector: (row) => row.uom_physical, sortable: true },
    { name: "Batch No", selector: (row) => row.batch_no, sortable: true },
    { name: "Expiry Date", selector: (row) => row.expiry_date, sortable: true },
    { name: "Condition", selector: (row) => row.condition, sortable: true },
    {
      name: "Auditor Observation",
      selector: (row) => row.auditor_observation,
      sortable: true,
    },
    {
      name: "Auditor Observation Class",
      selector: (row) => row.auditor_observation_class,
      sortable: true,
    },
    {
      name: "Mansa Remarks",
      selector: (row) => row.mansa_remarks,
      sortable: true,
    },
    // {
    //   name: "Senior Remarks",
    //   selector: (row) => row.senior_remarks,
    //   sortable: true,
    // },
    {
      name: "Senior Remarks",
      selector: (row) => row.update_senior_remarks,
      cell: (row) => (
        <>
          <div>
            <>
              <span className="">
                {popupSeniorRemarks?.inputValue
                  ? popupSeniorRemarks?.inputValue
                  : row?.senior_remarks}
              </span>
              <div className="" onClick={() => handleRemarksButtonClick(row)}>
                <i class="bi bi-patch-plus fs-3"></i>
              </div>
            </>
          </div>

          {popupSeniorRemarks.visible && (
            <div className="modal-overlay">
              <div className="modal-container">
                <h4 className="modal-title">Senior Remarks</h4>
                <textarea
                  type="text"
                  placeholder="write here .."
                  value={popupSeniorRemarks.inputValue}
                  onChange={handleSeniorRemarksInput}
                  className="modal-input"
                />
                <div className="modal-buttons">
                  <button
                    className="btn btn-primary modal-btn mt-2"
                    onClick={handleConfirmRemarks}
                  >
                    Submit
                  </button>
                  <button
                    className="btn btn-secondary modal-btn"
                    onClick={handleCancelRemarks}
                  >
                    Exit
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      ),
      sortable: true,
    },
    {
      name: "More Info 1",
      selector: (row) => row.more_info_type_1,
      sortable: true,
    },
    {
      name: "More Info 2",
      selector: (row) => row.more_info_type_2,
      sortable: true,
    },
    {
      name: "More Info 3",
      selector: (row) => row.more_info_type_3,
      sortable: true,
    },
    {
      name: "Client Sign Name",
      selector: (row) => row.client_sign_name,
      sortable: true,
    },
    {
      name: "Auditor Sign Name",
      selector: (row) => row.auditor_sign_name,
      sortable: true,
    },
    {
      name: "Client Signature",
      cell: (row) => (
        <div className="action-btn-group">
          {activeClientSigns.some(
            (r) => r.autoEntryNo === row.auto_entry_no
          ) ? (
            <>
              <div className="d-flex">
                <div className="signature-container p-0 m-0">
                  {activeClientSigns.find(
                    (r) => r.autoEntryNo === row.auto_entry_no
                  )?.signUrl && (
                    <div style={{ display: "flex" }}>
                      <img
                        src={
                          activeClientSigns.find(
                            (r) => r.autoEntryNo === row.auto_entry_no
                          ).signUrl
                        }
                        alt="Client Signature"
                        className="signature-img"
                      />
                      <span style={{ margin: "10px", cursor: "pointer" }}>
                        <i
                          class="bi bi-download fs-5"
                          onClick={(e) => downloadClientSign(e, row)}
                        ></i>
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <button
                className="close-icon"
                onClick={() =>
                  setActiveClientSigns((prev) =>
                    prev.filter((r) => r.autoEntryNo !== row.auto_entry_no)
                  )
                }
              >
                <i className="bi bi-x"></i>
              </button>
            </>
          ) : (
            <a
              className="ff-plus-jakarta btn btn-view"
              type="button"
              onClick={(e) => showClientSignature(e, row.auto_entry_no)}
            >
              <i className="bi bi-eye"></i> View
            </a>
          )}
        </div>
      ),
    },
    {
      name: "Auditor Signature",
      cell: (row) => (
        <div className="action-btn-group">
          {activeAuditorSigns.some(
            (r) => r.autoEntryNo === row.auto_entry_no
          ) ? (
            <>
              <div className="d-flex">
                <div className="signature-container p-0 m-0">
                  {activeAuditorSigns.find(
                    (r) => r.autoEntryNo === row.auto_entry_no
                  )?.signUrl && (
                    <div style={{ display: "flex" }}>
                      <img
                        src={
                          activeAuditorSigns.find(
                            (r) => r.autoEntryNo === row.auto_entry_no
                          ).signUrl
                        }
                        alt="Auditor Signature"
                        className="signature-img"
                      />
                      <span style={{ margin: "10px", cursor: "pointer" }}>
                        <i
                          class="bi bi-download fs-5"
                          onClick={(e) => downloadAuditorSign(e, row)}
                        ></i>
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <button
                className="close-icon"
                onClick={() =>
                  setActiveAuditorSigns((prev) =>
                    prev.filter((r) => r.autoEntryNo !== row.auto_entry_no)
                  )
                }
              >
                <i className="bi bi-x"></i>
              </button>
            </>
          ) : (
            <a
              className="ff-plus-jakarta btn btn-view"
              type="button"
              onClick={(e) => showAuditorSignature(e, row.auto_entry_no)}
            >
              <i className="bi bi-eye"></i> View
            </a>
          )}
        </div>
      ),
    },
    {
      name: "Images",
      cell: (row) => (
        <div className="action-btn-group">
          {activeRowImages.some((r) => r.autoEntryNo === row.auto_entry_no) ? (
            <>
              <div className="d-flex">
                <div className="imageList-container">
                  {activeRowImages
                    .find((r) => r.autoEntryNo === row.auto_entry_no)
                    ?.images.map((url, imgIndex) => (
                      <img
                        key={imgIndex}
                        src={url}
                        alt={`Image ${imgIndex + 1}`}
                        className="images-img"
                        style={{width:"60px", maxWidth:"80px", height:"40px", maxHeight:"70px", }}
                      />
                    ))}
                </div>
                {/* <div> */}
                  <button
                    className="close-icon"
                    onClick={() =>
                      setActiveRowImages((prev) =>
                        prev.filter((r) => r.autoEntryNo !== row.auto_entry_no)
                      )
                    }
                  >
                    <i className="bi bi-x"></i>
                  </button>
                {/* </div> */}
              </div>
              <button
                className="btn btn-download-all m-0 p-1"
                onClick={(e) => downloadAllImages(e, row)}
              >
                <i className="bi bi-download"></i>
              </button>
            </>
          ) : (
            <a
              className="ff-plus-jakarta btn btn-view"
              type="button"
              onClick={(e) => handleImages(e, row.auto_entry_no)}
            >
              <i className="bi bi-eye"></i> View
            </a>
          )}
        </div>
      ),
    },

    {
      name: "Doc Pdf",
      cell: (row) => (
        <div className="action-btn-group">
          <a
            className="ff-plus-jakarta btn btn-view"
            type="button"
            onClick={(e) => handleShowPdf(e, row.auto_entry_no)}
          >
            <i className="bi bi-eye"></i> View
          </a>
        </div>
      ),
    },
    {
      name: "Table Image",
      cell: (row, index) => (
        <div className="action-btn-group">
          {showTableImage && currentTableImage && showImageForRow === index ? (
            <>
              <div className="d-flex">
                <div className="image-container">
                  <img
                    src={currentTableImage}
                    alt="Table Image"
                    className="signature-img"
                  />
                </div>
              </div>
              <span
                style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  cursor: "pointer",
                }}
              >
                <i
                  className="bi bi-download fs-5"
                  onClick={(e) => downloadTableImage(e, row)}
                ></i>
              </span>
              <button className="close-icon" onClick={closeTableImage}>
                <i className="bi bi-x"></i>
              </button>
            </>
          ) : (
            <a
              className="ff-plus-jakarta btn btn-view"
              type="button"
              onClick={(e) => handleTableImage(e, index)}
            >
              <i className="bi bi-eye"></i> View
            </a>
          )}
        </div>
      ),
    },
    {
      name: "Table Image1",
      cell: (row, index) => (
        <div className="action-btn-group">
          {showTableImage1 &&
          currentTableImage1 &&
          showImageForRow1 === index ? (
            <>
              <div className="d-flex">
                <div className="image-container">
                  <img
                    src={currentTableImage1}
                    alt="Table Image1"
                    className="signature-img"
                  />
                </div>
              </div>
              <span
                style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                  cursor: "pointer",
                }}
              >
                <i
                  className="bi bi-download fs-5"
                  onClick={(e) => downloadTableImage1(e, row)}
                ></i>
              </span>
              <button className="close-icon" onClick={closeTableImage1}>
                <i className="bi bi-x"></i>
              </button>
            </>
          ) : (
            <a
              className="ff-plus-jakarta btn btn-view"
              type="button"
              onClick={(e) => handleTableImage1(e, index)}
            >
              <i className="bi bi-eye"></i> View
            </a>
          )}
        </div>
      ),
    },
    {
      name: "Excel",
      cell: (row) => (
        <DropdownButton
          title={<i className="bi bi-download"></i>}
          className="btn btn-download"
        >
          <Dropdown.Item onClick={() => downloadExcel([row])}>
            Excel
          </Dropdown.Item>
        </DropdownButton>
      ),
    },
    {
      name: "Cancel",
      cell: (row) => (
        <>
          <div>
            {!row.cancelReason ? (
              <button
                className="btn btn-danger"
                onClick={() => handleCancelClick(row)}
              >
                <i className="bi bi-x-circle"></i>
              </button>
            ) : (
              <span className="">{row?.cancelReason}</span>
            )}
          </div>
          {popupState.visible && (
            <div className="modal-overlay">
              <div className="modal-container">
                <h4 className="modal-title">Reason of Cancellation</h4>
                <textarea
                  type="text"
                  placeholder="write here..."
                  value={popupState.inputValue}
                  onChange={handleInputChange}
                  className="modal-input"
                />
                <div className="modal-buttons">
                  <button
                    className="btn btn-primary modal-btn mt-2"
                    onClick={() => handleConfirm(row)}
                  >
                    Submit
                  </button>
                  <button
                    className="btn btn-secondary modal-btn"
                    onClick={handleCancel}
                  >
                    Exit
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      ),
    },
  ];

  const toggleSearchField = (key) => {
    setSearchFields((prev) => {
      const updated = { ...prev };
      if (updated[key] !== undefined) {
        delete updated[key]; // Remove the field and clear the search
      } else {
        updated[key] = ""; // Add the field with an empty value
      }
      return updated;
    });
  };

  const handleSearchChange = (key, value) => {
    setSearchFields((prev) => ({ ...prev, [key]: value }));
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const validatephysicalQtyValue = (rowValue, searchValue) => {
    if (selectedOption === "greaterThan") {
      return rowValue > searchValue;
    }
    if (selectedOption === "lessThan") {
      return rowValue < searchValue;
    }
    if (selectedOption === "equalsTo") {
      return rowValue === searchValue;
    }
    return false;
  };

  const filterDataBySearch = (data) => {
    return data.filter((row) => {
      return Object.keys(searchFields).some((key) => {
        if (key === "total_qty" && selectedOption) {
          return validatephysicalQtyValue(row[key], searchFields[key]);
        } else {
          return (
            searchFields[key] &&
            String(row[key])
              .toLowerCase()
              .includes(String(searchFields[key]).toLowerCase())
          );
        }
      });
    });
  };

  const filterDataByDate = (data) => {
    return data.filter((row) => {
      const matchesDateRange =
        (!startDate || new Date(row.date) >= startDate) &&
        (!endDate || new Date(row.date) <= endDate);

      return matchesDateRange;
    });
  };

  let filteredData = flattenedData;

  // First filter data using search by column fields
  if (searchFields && Object.values(searchFields).filter((i) => i).length) {
    filteredData = filterDataBySearch(filteredData);
  }

  // Second filter data by dateRange
  if (startDate && endDate) {
    filteredData = filterDataByDate(filteredData);
  }

  const handleToastClose = (key) => {
    setSearchFields((prev) => ({ ...prev, [key]: "" }));
  };

  const getPreviewKey = (key, value) => {
    const columnObject = column.filter((item) => item.key === key)?.[0];
    let optionText = "";
    if (columnObject?.key === "total_qty") {
      if (selectedOption === "greaterThan") {
        optionText = "> (greater than)";
      }
      if (selectedOption === "lessThan") {
        optionText = "< (less than)";
      }
      if (selectedOption === "equalsTo") {
        optionText = "= (equals to)";
      }
    }
    return (
      <>
        <div className="tag-label">
          <span className="">{columnObject?.label}</span>:{" "}
          <strong>
            {" "}
            {optionText} {value}
          </strong>
        </div>
      </>
    );
  };

  const renderPreview = () => {
    const activeFields = Object.entries(searchFields).filter(
      ([_, value]) => value
    );
    if (activeFields.length > 0) {
      return (
        <div className="preview">
          {activeFields.map(([key, value]) => (
            <Toast
              key={key}
              className="toast-div"
              onClose={() => handleToastClose(key)}
            >
              <Toast.Header closeButton>
                {getPreviewKey(key, value)}
              </Toast.Header>
            </Toast>
          ))}
        </div>
      );
    }
    return null;
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const renderColumnSearchField = (searchFields, key) => {
    if (searchFields[key] !== undefined && key === "total_qty") {
      return (
        <>
          <Form className="radio-selections">
            <div>
              {/* Radio Button 1 */}
              <Form.Check
                type="radio"
                id="greaterThan"
                name="radioGroup"
                value="greaterThan"
                label="> (greater)"
                onChange={handleRadioChange}
                checked={selectedOption === "greaterThan"}
              />
            </div>
            <div>
              {/* Radio Button 2 */}
              <Form.Check
                type="radio"
                id="lessThan"
                name="radioGroup"
                value="lessThan"
                label="< (less)"
                onChange={handleRadioChange}
                checked={selectedOption === "lessThan"}
              />
            </div>
            <div>
              {/* Radio Button 3 */}
              <Form.Check
                type="radio"
                id="equalsTo"
                name="radioGroup"
                value="equalsTo"
                label="= (equal)"
                onChange={handleRadioChange}
                checked={selectedOption === "equalsTo"}
              />
            </div>
          </Form>
          {selectedOption && (
            <FormControl
              type="number"
              className="mt-2"
              placeholder={`Search by physical Qty.`}
              value={searchFields[key]}
              onChange={(e) =>
                handleSearchChange(key, parseInt(e.target.value, 10))
              }
            />
          )}
        </>
      );
    }

    if (searchFields[key] !== undefined) {
      return (
        <FormControl
          type="text"
          className="mt-2"
          placeholder={`Search by ${key}`}
          value={searchFields[key]}
          onChange={(e) => handleSearchChange(key, e.target.value)}
        />
      );
    }
  };

  return (
    <>
      <AdminLandingPage />
      <div className="reports-wrapper">
        <div className="container">
          <div className="w-100 row mb-3">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/admin-report-page")}
            >
              <i class="bi bi-arrow-left fs-4"></i>
            </span>
            <div className="col-12 col-md-6 mb-2 ">
              <h1 className="page-title">List of items</h1>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="filter-row">
                <div className="filter-dropdown">
                  <button
                    className="btn filter-button"
                    onClick={toggleDropdown}
                  >
                    <i className="bi bi-funnel"></i> Filter Columns
                  </button>
                  {dropdownOpen && (
                    <div className="dropdown-content">
                      {[
                        "auto_entry_no",
                        "total_qty",
                        "description",
                        "formType",
                        "sap_code_or_item_no",
                        "location",
                        "department_name",
                        "storage_name",
                      ].map((key) => (
                        <div key={key} className="dd-item">
                          <button
                            className="p-0 btn acc-header"
                            onClick={() => toggleSearchField(key)}
                          >
                            <span>
                              {column.find((col) => col.key === key)?.label}
                            </span>
                            <div className="btn-icon">
                              {searchFields[key] !== undefined ? (
                                <i className="bi bi-dash-circle"></i>
                              ) : (
                                <i className="bi bi-plus-circle"></i>
                              )}
                            </div>
                          </button>
                          {renderColumnSearchField(searchFields, key)}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {/* Date Picker */}
                <div className="date-picker-wrapper">
                  <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => setDateRange(update)}
                    isClearable={true}
                    placeholderText="Select date range"
                  />
                  <i class="bi bi-calendar"></i>
                </div>
              </div>
              {/* Preview */}
              {renderPreview()}

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  onClick={handleDownloadSelected}
                  className="btn filter-button"
                >
                  <i class="bi bi-download mx-1"></i>
                  Download
                </Button>
              </div>
            </div>
          </div>

          <div className="ff-plus-jakarta w-100 row">
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
              selectableRows
              onSelectedRowsChange={handleRowSelected}
              clearSelectedRows={toggleClearRows}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
