import React, { useState } from "react";
import { AdminLandingPage } from "../admin_landing_page";
import "./team.css";
import {
  FormControl,
  Pagination,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";

export const TeamList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Default rows per page

  const initialData = [
    {
      sno: 1,
      teamName: "Team 1",
      userName: "Kunal Shah",
      userCategory: "User",
    },
    {
      sno: 2,
      teamName: "Team 2",
      userName: "Raj Chotai",
      userCategory: "User",
    },
    {
      sno: 3,
      teamName: "Team 3",
      userName: "Abhay Sheth",
      userCategory: "User",
    },
    {
      sno: 4,
      teamName: "Team 4",
      userName: "Shivkumar Sharma",
      userCategory: "User",
    },
    {
      sno: 5,
      teamName: "Team 5",
      userName: "Raj Padmani",
      userCategory: "User",
    },
    { sno: 6, teamName: "Team 1", userName: "Chirag Mehta", userCategory: "Admin" },
    { sno: 7, teamName: "Team 2", userName: "Meet Shah", userCategory: "Admin" },
    { sno: 8, teamName: "Team 3", userName: "Manthan", userCategory: "Admin" },
  ];

  const [columns] = useState([
    { key: "sno", label: "Sr. No." },
    { key: "teamName", label: "Team Name" },
    { key: "userName", label: "User Name" },
    { key: "userCategory", label: "Category" },
  ]);

  // Handle search box input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter and Paginate Data
  const filteredData = initialData.filter((row) =>
    columns.some((col) =>
      String(row[col.key]).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const totalRows = filteredData.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const paginatedData = filteredData.slice(
    startIndex,
    startIndex + rowsPerPage
  );

  // Handle Pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle Rows Per Page Change
  const handleRowsPerPageChange = (rows) => {
    setRowsPerPage(rows);
    setCurrentPage(1); // Reset to first page
  };

  return (
    <>
      <AdminLandingPage />
      <div className="reports-wrapper">
        <div className="container">
          <div className="w-100 column-set">
            <div className="top-row">
              <h1 className="m-0 page-title">Team List</h1>

              {/* Global Search */}
              <div className="form-group--search">
                <FormControl
                  type="text"
                  className="ff-plus-jakarta form-control"
                  placeholder="Search any table data..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <i className="bi bi-search"></i>
              </div>
            </div>

            {/* Table */}
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {columns.map((col) => (
                      <th key={col.key}>{col.label}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData.map((row) => (
                    <tr key={row.sno}>
                      {columns.map((col) => (
                        <td key={col.key}>{row[col.key]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Pagination Controls */}
            <div className="pagination-controls">
              <DropdownButton
                id="rows-per-page"
                title={
                  <>
                    Rows per page:{" "}
                    <span>
                      {rowsPerPage}
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                  </>
                }
                className="ddbtn">
                {[5, 10, 15, 20].map((rows) => (
                  <Dropdown.Item
                    key={rows}
                    onClick={() => handleRowsPerPageChange(rows)}>
                    {rows}
                  </Dropdown.Item>
                ))}
              </DropdownButton>

              <Pagination>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => handlePageChange(page)}>
                      {page}
                    </Pagination.Item>
                  )
                )}
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
