import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

const ProtectedRoute = ({ element, condition, redirectTo = "/" }) => {
  return condition ? element : <Navigate to={redirectTo} replace />;
};

ProtectedRoute.propTypes = {
  element: PropTypes.element.isRequired,
  condition: PropTypes.bool.isRequired,
  redirectTo: PropTypes.string, // Optional: where to redirect if the condition fails
};

export default ProtectedRoute;
