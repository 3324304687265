import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";
import "./distributor_form.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { DynamicTableForDistributor } from "./dynamicTable.js";
import { jsPDF } from "jspdf";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";

import * as XLSX from "xlsx";
import Signature from "./signatureCanvas.js";
import html2pdf from "html2pdf.js";
import { LandingPage } from "../landing.js";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Loader from "../../resubale_components/loader/loader.js";

const clientOptions = [
  { value: "Mansa Sugar Pvt Ltd", label: "Mansa Sugar Pvt Ltd" },
  { value: "Triefin", label: "Triefin" },
];

const typeOptions = [
  { value: "New Form", label: "New Form" },
  { value: "Recount", label: "Recount" },
  { value: "Cancelled/Re-entered", label: "Cancelled/Re-entered" },
  { value: "Against Variance", label: "Against Variance" },
];

const departmentOptions = [
  { value: " MS -Spares", label: " MS -Spares" },
  { value: "MS - Agro Workshop", label: "MS - Agro Workshop" },
  { value: "MS - Agro F&C", label: "MS - Agro F&C" },
  { value: "MS- HH", label: "MS- HH" },
  { value: "Store", label: "Store" },
  { value: "MS- Factory Mill", label: "MS- Factory Mill" },
  { value: "MS- Factory Mill", label: "MS- Factory Mill" },
  { value: "MS- Factory Process", label: "MS- Factory Process" },
  { value: "MS- Electrical", label: "MS- Electrical" },
  { value: "MS- Boiler", label: "MS- Boiler" },
  { value: "MS- Irrigations", label: "MS- Irrigations" },
  { value: "MS- Consumables", label: "MS- Consumables" },
  { value: "MS- Packaging", label: "MS- Packaging" },
  { value: "MS- Civil", label: "MS- Civil" },
  { value: "Harvest & Haulage", label: "Harvest & Haulage" },
  { value: "Agro", label: "Agro" },
  { value: "Factory", label: "Factory" },
  { value: "Fabrication", label: "Fabrication" },
  { value: "Irrigation", label: "Irrigation" },
  { value: "Electrical", label: "Electrical" },
  { value: "Fikombo", label: "Fikombo" },
  { value: "Lwansa", label: "Lwansa" },
  { value: "Fuel", label: "Fuel" },
  { value: "Fertilisers", label: "Fertilisers" },
  { value: "Chemicals", label: "Chemicals" },
  { value: "Packaging", label: "Packaging" },
  { value: "Civil", label: "Civil" },
  { value: "Unknown Department", label: "Unknown Department" },
];

const locationOptions = [
  { value: "Main Store", label: "Main Store" },
  { value: "Agro Workshop", label: "Agro Workshop" },
  {
    value: "Agro Workshop - Implements Yard",
    label: "Agro Workshop - Implements Yard",
  },
  { value: "Harvest & Haulage Workshop ", label: "Harvest & Haulage Workshop" },
  { value: "Factory Workshop", label: "Factory Workshop" },
  { value: "Fabrication Workshop", label: "Fabrication Workshop" },
  { value: "Factory - Turbine floor", label: "Factory - Turbine floor" },
  { value: "Factory -Milling house", label: "Factory -Milling house" },
  { value: "Factory - Boiler Floor", label: "Factory - Boiler Floor" },
  { value: "Factory - Process House", label: "Factory - Process House" },
  { value: "Finished Goods Warehouse", label: "Finished Goods Warehouse" },
  { value: "Packaging Warehouse", label: "Packaging Warehouse" },
  { value: "Fikombo Office", label: "Unknown Department" },
  {
    value: "Fikombo West Container- Booster 3",
    label: "Fikombo West Container- Booster 3",
  },
  { value: "UnknoLwansa Officewn ", label: "Lwansa Office " },
  {
    value: "Lwansa East Container -L16 ",
    label: "Lwansa East Container -L16 ",
  },
  { value: "Canteen ", label: "Canteen " },
  {
    value: "Dormitory- Management House ",
    label: "Dormitory- Management House ",
  },
  { value: "Factory Pipe Yard ", label: "Factory Pipe Yard " },
  { value: " Scrap Yard", label: "Scrap Yard " },
  { value: "Tyre Yard", label: "Tyre Yard" },
  { value: "Fuel tank ", label: "Fuel tank" },
  { value: "Lime container ", label: " Lime container" },
  { value: "Cable Yard ", label: "Cable Yard" },
  { value: "Other", label: "Other" },
];

export const FormForDistributor = () => {
  const [formFields, setFormFields] = useState([
    {
      formType: "",
      client_team_name: "",
      client: "",
      nsi: "",
      sap_code_or_item_no: "",
      physical_count_date: new Date().toISOString().split("T")[0],
      images: [],
      document: [],
      remarks: "",
      auto_entry_no: "",
      previous_aen: "",
      description: "",
      item_group_name: "",
      item_sub_group_2: "",
      item_sub_group_3: "",
      team_name: localStorage.getItem("team name")
        ? `${localStorage.getItem("team name")} - ${localStorage.getItem(
            "username"
          )}`
        : "",
      floor: "",
      storage_name: "",
      department_name: "",
      compartment_name: "",
      aisle_name: "",
      location: "",
      document: "",
      client_signature: null,
      auditor_signature: null,
      client_sign_name: "",
      auditor_sign_name: "",
      mixbox: "",
      total_qty: 0,
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [tableDataForPayLoad, setTableDataForPayLoad] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isMixboxChecked, setIsMixboxChecked] = useState(false);
  const [stream, setStream] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const videoRef = useRef(null);

  const [isCustomCollapsed, setIsCustomCollapsed] = useState(false);
  const [isPhotosCollapsed, setIsPhotosCollapsed] = useState(false);
  const [isDocumentsCollapsed, setIsDocumentsCollapsed] = useState(false);
  const [isSignaturesCollapsed, setIsSignaturesCollapsed] = useState(false);

  const [disablePdfGenerateButton, setDisablePdfGenerateButton] =
    useState(false);

  const [uom, setUom] = useState("");
  const [tableLocation, setTableLocation] = useState("");
  const [sapCodeShow, setSapCodeShow] = useState("");
  const [descriptionShow, setDescriptionShow] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [documentsStream, setDocumentsStream] = useState(null);
  const [itemMasterData, setItemMasterData] = useState(null);
  const documentsVideoRef = useRef(null);

  const [descriptionData, setDescriptionData] = useState(null);
  const [formFileData, setFormFileData] = useState(null);

  const [sapData, setSapData] = useState(null);
  const [itemGroup, setItemGroup] = useState(null);
  const [group_2, setGroup_2] = useState(null);
  const [subgroup_3, setSubGroup_3] = useState(null);

  const [showDownloadFolder, setShowDownloadFolder] = useState(false);

  const openDB = (userId) => {
    const dbName = `CSVDataStore_${localStorage.getItem("username")}`;
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, 1);

      request.onupgradeneeded = (e) => {
        const db = e.target.result;
        if (!db.objectStoreNames.contains("csvData")) {
          db.createObjectStore("csvData", {
            keyPath: "id",
            autoIncrement: true,
          });
        }
      };

      request.onsuccess = (e) => {
        resolve(e.target.result);
      };

      request.onerror = (e) => {
        reject("IndexedDB error: " + e.target.error);
      };
    });
  };

  const fetchDataFromIndexedDB = (userId) => {
    return new Promise((resolve, reject) => {
      openDB(userId)
        .then((db) => {
          const transaction = db.transaction("csvData", "readonly");
          const store = transaction.objectStore("csvData");

          const request = store.getAll(); // Get all the data stored in the object store
          request.onsuccess = () => {
            resolve(request.result);
          };
          request.onerror = (e) => {
            reject("Failed to fetch data from IndexedDB: " + e.target.error);
          };
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getDataFromIndexedDB = (userId) => {
    fetchDataFromIndexedDB(userId)
      .then((data) => setItemMasterData(data))
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    const userId = localStorage.getItem("username");
    if (userId) {
      getDataFromIndexedDB(userId);
    }
  }, [itemMasterData?.length]);

  const openDB1 = () => {
    const dbName = `FormDataDB_${localStorage.getItem("username")}`;
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, 2);

      request.onupgradeneeded = (e) => {
        const db = e.target.result;
        if (!db.objectStoreNames.contains("formData")) {
          db.createObjectStore("formData", {
            keyPath: "id",
            autoIncrement: true,
          });
        }
      };

      request.onsuccess = (e) => {
        resolve(e.target.result);
      };

      request.onerror = (e) => {
        reject("IndexedDB error: " + e.target.error);
      };
    });
  };

  const fetchDataFromIndexedDB1 = () => {
    return new Promise((resolve, reject) => {
      openDB1()
        .then((db) => {
          const transaction = db.transaction("formData", "readonly");
          const store = transaction.objectStore("formData");

          const request = store.getAll(); // Get all the data stored in the object store
          request.onsuccess = () => {
            resolve(request.result);
          };
          request.onerror = (e) => {
            reject("Failed to fetch data from IndexedDB: " + e.target.error);
          };
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getDataFromIndexedDB1 = () => {
    fetchDataFromIndexedDB1()
      .then((storedData) => {
        setFormFileData(storedData);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const userId = localStorage.getItem("username");
    if (userId) {
      getDataFromIndexedDB1(userId);
    }
  }, [formFileData?.length]);

  const handleTableDataChange = (updatedTableData, totalQuantity) => {
    const sanitizedTableData = updatedTableData.map((row) => {
      return Object.fromEntries(
        Object.entries(row).map(([key, value]) => {
          if (key === "physical_qty") {
            // Ensure physical_qty is a valid number
            return [key, Number(value) || 0];
          }
          if (key === "image") {
            // Ensure image is a valid file or default to an empty object
            return [key, value && typeof value === "object" ? value : {}];
          }
          return [key, value || ""];
        })
      );
    });

    setTableDataForPayLoad(sanitizedTableData);

    setFormFields((prevFields) => [
      {
        ...prevFields[0],
        total_qty: totalQuantity,
      },
    ]);
  };
  useEffect(() => {
    if (itemMasterData) {
      const uniqueSapList = [
        ...new Set(itemMasterData?.map((item) => item.item_no_or_sap_id)),
      ]?.map((item) => ({
        value: item,
        label: item,
      }));
      setSapData(uniqueSapList);

      const uniqueDescriptionList = [
        ...new Set(
          itemMasterData
            ?.map((item) => item.item_description)
            .filter((item) => item !== null && item !== "")
        ),
      ]?.map((item) => ({
        value: item,
        label: item,
      }));

      setDescriptionData(uniqueDescriptionList);

      const itemGroupList = [
        ...new Set(
          itemMasterData
            ?.map((item) => item.group)
            .filter((item) => item !== null && item !== "")
        ),
      ]?.map((item) => ({
        value: item,
        label: item,
      }));
      setItemGroup(itemGroupList);

      const group_2List = [
        ...new Set(
          itemMasterData
            ?.map((item) => item.group_2)
            .filter((item) => item !== null && item !== "")
        ),
      ]?.map((item) => ({
        value: item,
        label: item,
      }));
      setGroup_2(group_2List);

      const subgroup_3List = [
        ...new Set(
          itemMasterData
            ?.map((item) => item.subgroup_3)
            .filter((item) => item !== null && item !== "")
        ),
      ]?.map((item) => ({
        value: item,
        label: item,
      }));

      setSubGroup_3(subgroup_3List);
    }
  }, [itemMasterData?.length]);

  const handleToggle = (e) => {
    e.preventDefault();

    setFormFields((prevFields) => {
      const updatedFields = [...prevFields];

      // Ensure the first object exists
      if (updatedFields[0]) {
        updatedFields[0] = {
          ...updatedFields[0],
          description: "",
          item_group_name: "",
          item_sub_group_2: "",
          item_sub_group_3: "",
        };
      }

      return updatedFields;
    });

    setIsChecked((prevChecked) => {
      const newChecked = !prevChecked;

      if (newChecked) {
        showNsiTextBox();
      }

      return newChecked; // Toggle the checkbox state
    });
  };

  // Function to generate NSI code
  const showNsiTextBox = () => {
    const now = new Date();
    const formattedDateTime = `NSI${String(now.getMonth() + 1).padStart(
      2,
      "0"
    )}${String(now.getDate()).padStart(2, "0")}${String(
      now.getHours()
    ).padStart(2, "0")}${String(now.getMinutes()).padStart(2, "0")}${String(
      now.getSeconds()
    ).padStart(2, "0")}`;

    setFormFields((prevFormFields) =>
      prevFormFields.map((form, index) => {
        return index === 0 ? { ...form, nsi: formattedDateTime } : form;
      })
    );
  };

  const handleSelectChange = (index, field, selectedOption) => {
    setFormFields((prev) =>
      prev.map((formField, i) =>
        i === index
          ? { ...formField, [field]: selectedOption?.value || "" }
          : formField
      )
    );
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedFields = [...formFields];

    updatedFields[index][name] = value;

    if (name === "description") {
      setDescriptionShow(value);
      const matchingData = formFileData?.find(
        (item) => item?.[0]?.description?.toLowerCase() === value?.toLowerCase()
      );

      if (matchingData) {
        updatedFields[index].nsi = matchingData[0].nsi;
      } else {
        updatedFields[index].nsi = updatedFields[index].nsi || "";
      }
    }

    if (name === "location") {
      updatedFields[index].auto_entry_no = generateUniqueId(value);
      setTableLocation(updatedFields[index]?.location);
    }

    setFormFields(updatedFields);
  };

  const handleItemNoSelect = (selectedOption) => {
    if (!selectedOption) {
      setFormFields((prevFields) => {
        return prevFields.map(
          (field, index) =>
            index === 0
              ? {
                  ...field,
                  sap_code_or_item_no: "",
                  item_group_name: "",
                  item_sub_group_2: "",
                  item_sub_group_3: "",
                  description: "",
                }
              : field // Keep other fields unchanged
        );
      });
      setUom("");
      return;
    }

    const selectedSapCode = selectedOption.value;

    const selectedItemData = itemMasterData?.find(
      (item) => item.item_no_or_sap_id === selectedSapCode
    );

    if (selectedItemData) {
      setFormFields((prevFields) => {
        const updatedFields = [...prevFields];
        updatedFields[0] = {
          ...updatedFields[0],
          sap_code_or_item_no: selectedItemData.item_no_or_sap_id || "NA",
          item_group_name: selectedItemData.group || "NA",
          item_sub_group_2: selectedItemData.group_2 || "NA",
          item_sub_group_3: selectedItemData.subgroup_3 || "NA",
          description: selectedItemData.item_description || "NA",
        };
        setSapCodeShow(selectedItemData.item_no_or_sap_id);
        setDescriptionShow(selectedItemData.item_description);
        return updatedFields;
      });

      setUom(selectedItemData.UoM || "");
    } else {
      console.warn(
        "No matching item found in itemMasterData for the selected SAP code."
      );
      setUom("");
    }
  };

  const handleDescription = (selectedOption) => {
    if (!selectedOption) {
      setFormFields((prevFields) => {
        return prevFields.map(
          (field, index) =>
            index === 0
              ? {
                  ...field,
                  sap_code_or_item_no: "",
                  item_group_name: "",
                  item_sub_group_2: "",
                  item_sub_group_3: "",
                  description: "",
                }
              : field // Keep other fields unchanged
        );
      });
      setUom("");
      return;
    }

    const selectedDescription = selectedOption.value;

    const selectedItemData = itemMasterData?.find(
      (item) => item.item_description === selectedDescription
    );

    if (selectedItemData) {
      setFormFields((prevFields) => {
        const updatedFields = [...prevFields];
        updatedFields[0] = {
          ...updatedFields[0],
          sap_code_or_item_no: selectedItemData.item_no_or_sap_id || "NA",
          item_group_name: selectedItemData.group || "NA",
          item_sub_group_2: selectedItemData.group_2 || "NA",
          item_sub_group_3: selectedItemData.subgroup_3 || "NA",
          description: selectedItemData.item_description || "NA",
        };
        setSapCodeShow(selectedItemData.item_no_or_sap_id);
        setDescriptionShow(selectedItemData.item_description);
        return updatedFields;
      });

      setUom(selectedItemData.UoM || "");
    } else {
      console.warn(
        "No matching item found in itemMasterData for the selected description."
      );
      setUom("");
    }
  };

  const handleMixboxChange = (index, value) => {
    setFormFields((prevFields) =>
      prevFields.map((field, i) =>
        i === index ? { ...field, mixbox: value } : field
      )
    );
  };

  // photos uplaod and capture

  const handleFileChange = (index, event) => {
    if (!event || !event.target.files) {
      console.error("No files selected or event not properly passed.");
      return;
    }

    const files = Array.from(event.target.files);
    const validFiles = files.filter((file) => file.size <= 25 * 1024 * 1024); // 25MB limit

    const updatedFields = [...formFields];
    const imageObjects = validFiles.map((file) => {
      // Add FI_ prefix to the filename
      const updatedFile = new File([file], `FI_${file.name}`, {
        type: file.type,
        lastModified: file.lastModified,
      });

      return {
        image: URL.createObjectURL(updatedFile), // The image URL (blob URL)
        file: updatedFile, // The actual updated file object
      };
    });

    updatedFields[index].images = [
      ...(updatedFields[index].images || []),
      ...imageObjects,
    ];

    setFormFields(updatedFields);
  };

  const handleCapturePhoto = (index) => {
    if (videoRef.current) {
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      canvas.getContext("2d").drawImage(videoRef.current, 0, 0);

      const newPhoto = canvas.toDataURL("image/png"); // Captured photo as base64

      // Convert base64 to Blob
      const byteString = atob(newPhoto.split(",")[1]);
      const mimeString = newPhoto.split(",")[0].split(":")[1].split(";")[0];
      const ab = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ab[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: mimeString });

      // Create a File object from the Blob
      const file = new File([blob], `captured_image_${Date.now()}.png`, {
        type: mimeString,
      });

      const updatedFields = [...formFields];

      // Create the image object with both base64 and file
      const capturedImageObject = {
        image: newPhoto, // Base64 of the captured image
        file: file, // The actual file object created from Blob
      };

      // Update the images array without affecting other fields
      updatedFields[index].images = [
        ...(updatedFields[index].images || []),
        capturedImageObject,
      ];

      setFormFields(updatedFields);
    }
  };
  const handleOpenCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setStream(stream);
    } catch (error) {
      console.error("Camera access denied", error);
    }
  };

  const handleCloseCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
  };

  useEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    }
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [stream]);

  const deletePhoto = (index, photoIndex) => {
    const updatedFields = [...formFields];
    updatedFields[index].images.splice(photoIndex, 1);
    setFormFields(updatedFields);
  };

  // documents upload and capture

  const handleFileChangeForDocuments = (index, event) => {
    if (!event || !event.target.files) {
      console.error("No files selected or event not properly passed.");
      return;
    }

    const files = Array.from(event.target.files);
    const previewUrls = files.map((file) => {
      const blobUrl = URL.createObjectURL(file);
      return blobUrl;
    });

    const data = [...formFields];
    if (!data[index]) {
      data[index] = { documents: [] }; // Initialize if undefined
    }

    data[index].documents = [...(data[index].documents || []), ...previewUrls];
    setDisablePdfGenerateButton(true);
    setFormFields(data);
  };

  const handleCaptureDocuments = (index) => {
    if (documentsVideoRef.current) {
      const canvas = document.createElement("canvas");
      canvas.width = documentsVideoRef.current.videoWidth;
      canvas.height = documentsVideoRef.current.videoHeight;

      const context = canvas.getContext("2d");
      context.drawImage(documentsVideoRef.current, 0, 0);

      canvas.toBlob((blob) => {
        if (blob) {
          const newPhoto = URL.createObjectURL(blob);

          const data = [...formFields];
          if (!data[index]) {
            data[index] = { documents: [] };
          }

          data[index].documents = [...(data[index].documents || []), newPhoto];
          setDisablePdfGenerateButton(true);
          setFormFields(data);
        } else {
          console.error("Failed to convert canvas to Blob.");
        }
      }, "image/png");
    }
  };

  const handleOpenCameraForDocuments = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setDocumentsStream(stream);
    } catch (error) {
      console.error("Camera access denied", error);
    }
  };

  const handleCloseCameraForDocuments = () => {
    if (documentsStream) {
      documentsStream.getTracks().forEach((track) => track.stop());
      setDocumentsStream(null);
    }
  };

  useEffect(() => {
    if (documentsStream && documentsVideoRef.current) {
      documentsVideoRef.current.srcObject = documentsStream;
      documentsVideoRef.current.onloadedmetadata = () => {
        documentsVideoRef.current.play();
      };
    }
    return () => {
      if (documentsStream) {
        documentsStream.getTracks().forEach((track) => track.stop());
        if (documentsVideoRef.current) {
          documentsVideoRef.current.srcObject = null; // Clear the srcObject
        }
      }
    };
  }, [documentsStream]);

  const handleGeneratePDF = (index) => {
    setLoading(true);
    const doc = new jsPDF();
    const photos = formFields[index]?.documents || [];

    if (photos.length === 0) {
      setLoading(false);
      toast.error("No photos available to generate a PDF.", {
        autoClose: 3000,
      });
      return;
    }

    let processedImages = 0;

    photos.forEach((photo, idx) => {
      const img = new Image();
      img.src = photo;

      img.onload = () => {
        const MAX_WIDTH = 800;
        const MAX_HEIGHT = 600;
        let { width, height } = img;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height = Math.round((height * MAX_WIDTH) / width);
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width = Math.round((width * MAX_HEIGHT) / height);
            height = MAX_HEIGHT;
          }
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              const compressedPhoto = URL.createObjectURL(blob);
              const pageWidth = doc.internal.pageSize.getWidth();
              const pageHeight = doc.internal.pageSize.getHeight();

              const aspectRatio = width / height;
              const imgWidth = pageWidth - 20;
              const imgHeight = imgWidth / aspectRatio;

              const x = 10;
              let y = 10;

              if (idx > 0) {
                doc.addPage();
              }

              if (imgHeight < pageHeight - 20) {
                y = (pageHeight - imgHeight) / 2;
              }

              try {
                doc.addImage(
                  compressedPhoto,
                  "JPEG",
                  x,
                  y,
                  imgWidth,
                  imgHeight
                );
                URL.revokeObjectURL(compressedPhoto); // Clean up
              } catch (error) {
                console.error("Error adding image to PDF:", error);
              }

              processedImages++;
              if (processedImages === photos.length) {
                const pdfBlob = doc.output("blob");

                const pdfFile = new File([pdfBlob], "document.pdf", {
                  type: "application/pdf",
                });

                const updatedFormFields = [...formFields];
                updatedFormFields[index].document = pdfFile;
                setFormFields(updatedFormFields);
                setLoading(false);
                toast.success("PDF generated");
              }
            }
          },
          "image/jpeg",
          0.7
        );
      };

      img.onerror = () => {
        setLoading(false);
        toast.error("An error occurred while loading an image.");
      };
    });
  };

  const deleteDocuments = (formIndex, photoIndex) => {
    const data = [...formFields];
    data[formIndex].documents.splice(photoIndex, 1);
    setFormFields(data);
  };

  // Function to open IndexedDB and create an object store if not exists
  const openIndexedDB = async () => {
    const dbName = `FormDataDB_${localStorage.getItem("username")}`;
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, 2); // Increase version number if needed

      request.onupgradeneeded = (e) => {
        const db = e.target.result;
        if (!db.objectStoreNames.contains("formData")) {
          const store = db.createObjectStore("formData", {
            keyPath: "id",
            autoIncrement: true,
          });
        }
      };

      request.onsuccess = (e) => {
        resolve(e.target.result);
      };

      request.onerror = (e) => {
        reject("Error opening IndexedDB: " + e.target.error);
      };
    });
  };

  const openDatabase = () => {
    const dbName = `counter_${localStorage.getItem("username")}`;
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, 1);

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        // Create an object store for the sequence number, if it doesn't exist
        if (!db.objectStoreNames.contains("count")) {
          const objectStore = db.createObjectStore("count", { keyPath: "id" });
          objectStore.add({ id: 1, sequenceNumber: 0 }); // Initialize the sequence number
        }
      };

      request.onsuccess = (event) => {
        resolve(event.target.result); // Return the database instance
      };

      request.onerror = (event) => {
        reject(event.target.error); // Handle errors
      };
    });
  };

  const getSequenceNumber = async () => {
    const db = await openDatabase();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction("count", "readwrite");
      const objectStore = transaction.objectStore("count");
      const request = objectStore.get(1); // Get the sequence number with ID 1

      request.onsuccess = (event) => {
        const data = event.target.result;
        resolve(data ? data.sequenceNumber : 0); // Return the current sequence number, or 0 if not found
      };

      request.onerror = (event) => {
        reject(event.target.error); // Handle errors
      };
    });
  };

  const updateSequenceNumber = async (newSequenceNumber) => {
    const db = await openDatabase();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction("count", "readwrite");
      const objectStore = transaction.objectStore("count");
      const request = objectStore.put({
        id: 1,
        sequenceNumber: newSequenceNumber,
      }); // Update the sequence number

      request.onsuccess = () => {
        resolve(); // Successfully updated
      };

      request.onerror = (event) => {
        reject(event.target.error); // Handle errors
      };
    });
  };

  const getNextSequenceNumber = async () => {
    try {
      let currentSequenceNumber = await getSequenceNumber(); // Get the current sequence number

      // Increment the sequence number, and reset if it reaches 99999
      currentSequenceNumber = (currentSequenceNumber + 1) % 100000;

      // Update the sequence number in IndexedDB
      await updateSequenceNumber(currentSequenceNumber);

      // Return the padded sequence number as a string with leading zeros
      return currentSequenceNumber.toString().padStart(5, "0");
    } catch (error) {
      console.error("Failed to get or update sequence number:", error);
    }
  };

  // Function to save data to IndexedDB
  const saveFormDataToIndexedDB = async (data, id = null) => {
    try {
      const db = await openIndexedDB();
      const transaction = db.transaction("formData", "readwrite");
      const store = transaction.objectStore("formData");

      // If you are passing an id, ensure it's valid
      if (id !== null && id !== undefined) {
        const newData = { ...data, id };
        store.add(newData); // Explicitly add the new data with the provided id
      } else {
        const newData = { ...data }; // Allow IndexedDB to generate the id automatically
        store.add(newData); // This will add the data and auto-generate the id
      }

      transaction.oncomplete = () => {
        console.log("Form data added successfully to IndexedDB.");
      };

      transaction.onerror = (error) => {
        console.error("Error saving to IndexedDB:", error.target.error);
      };
    } catch (error) {
      console.error("Error opening IndexedDB:", error);
    }
  };

  const saveFormDataToDesktop = async () => {
    setLoading(true);
    try {
      const sequenceNumber = await getNextSequenceNumber();

      const formFieldsMandatory = [
        "aisle_name",
        "team_name",
        "client_team_name",
        "client",
        "floor",
        "location",
        "storage_name",
        "client_signature",
        "auditor_signature",
        "department_name",
        "description",
      ];

      const tableFieldsMandatory = [
        "package_no",
        "physical_qty",
        "uom_physical",
      ];

      const missingFormFields = formFieldsMandatory.filter(
        (field) => !formFields[0][field]
      );

      // Check missing fields in tableDataForPayLoad
      const missingTableFields = tableFieldsMandatory.filter((field) => {
        // Ensure tableDataForPayLoad is not null or undefined
        if (!tableDataForPayLoad || tableDataForPayLoad.length === 0)
          return true; // No table data, so all fields are considered missing

        // Check if every row is missing the mandatory field
        return !tableDataForPayLoad.some((row) => row[field]);
      });

      // Handle missing fields
      if (missingFormFields.length > 0 || missingTableFields.length > 0) {
        const missingFields = [...missingFormFields, ...missingTableFields];

        missingFields.forEach((field) => {
          let fieldName = field
            .replace(/_/g, " ") // Replace underscores with spaces
            .replace(/^\w/, (c) => c.toUpperCase());

          if (field === "client_signature") fieldName = "client Signature";
          if (field === "auditor_signature") fieldName = "auditor Signature";
          setLoading(false);
          toast.error(`${fieldName} is required!`);
        });

        return;
      }

      if (formFields[0]?.images.length === 0) {
        setLoading(false);
        toast.error("Images are required!");
        return;
      }

      if (
        formFields[0].formType === "Recount" ||
        formFields[0].formType === "Cancelled/Re-entered" ||
        formFields[0].formType === "Against Variance"
      ) {
        if (formFields[0]?.previous_aen === "") {
          setLoading(false);
          toast.error("previous aen no is required!");
          return;
        }
      }

      // Clone form content and remove loader before PDF generation
      const formContent = document.querySelector("#form-content");
      const clonedContent = formContent.cloneNode(true);

      // Hide the loader before generating the PDF
      const loaderElement = clonedContent.querySelector(".loader-overlay");
      if (loaderElement) {
        loaderElement.style.display = "none";
      }

      // Generate PDF without loader
      const pdfBlob = await html2pdf()
        .from(clonedContent)
        .set({
          margin: 10,
          filename: "preview-form.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 5 },
          jsPDF: { unit: "mm", format: "a2", orientation: "landscape" },
        })
        .outputPdf("blob");

      const pdfFile = new File([pdfBlob], "preview.pdf", {
        type: "application/pdf",
      });

      // Update state and execute dependent logic within the callback
      setFormFields((prevFormFields) => {
        const updatedFields = prevFormFields.map((field) => ({
          ...field,
          tabledata: tableDataForPayLoad,
          auto_entry_no: `${field.auto_entry_no}${sequenceNumber}`,
          previewPdf: pdfFile,
        }));

        saveFormDataToIndexedDB(updatedFields);

        return updatedFields; // Update state
      });

      // Combine formFields and tableData
      const combinedData = formFields.map((formField, index) => ({
        ...formField,
        tabledata: tableDataForPayLoad, // Attach tableData from tableDataForPayload
      }));

      // Flatten the tableData for Excel but only show formFields once
      const flattenedTableData = [];

      combinedData.forEach((data) => {
        if (data.tabledata && data.tabledata.length > 0) {
          // Only add form fields once for each table row
          data.tabledata.forEach((tableRow, idx) => {
            // Include form fields only in the first table row
            const rowData =
              idx === 0 ? { ...data, ...tableRow } : { ...tableRow };
            flattenedTableData.push(rowData);
          });
        }
      });

      // Replace empty or null values with "NA" in flattened data
      const updatedFlattenedData = flattenedTableData.map((field) => {
        const updatedField = { ...field };

        for (const key in updatedField) {
          if (
            updatedField[key] === null ||
            updatedField[key] === "" ||
            (Array.isArray(updatedField[key]) && updatedField[key].length === 0)
          ) {
            updatedField[key] = "NA";
          }
        }
        if (updatedField.auto_entry_no) {
          updatedField.auto_entry_no = `${updatedField.auto_entry_no}${sequenceNumber}`;
        }
        return updatedField;
      });

      // const autoEntrySuffix = formFields[0].auto_entry_no.slice(-3).sequenceNumber;
      const autoEntrySuffix = `${formFields[0].auto_entry_no
        .toString()
        .slice(-3)
        .padStart(2, "0")}${sequenceNumber}`;

      // Convert data to worksheet and workbook
      const worksheet = XLSX.utils.json_to_sheet(updatedFlattenedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        `Excel-${autoEntrySuffix}`
      );

      toast.success(
        "Form data, table data, images, PDFs, and signatures saved successfully!"
      );
      setLoading(false);
      setShowDownloadFolder(true);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to save data. Please try again.");
    }
  };

  const generateUniqueId = (location) => {
    const sanitizedLocation = location?.replace(/[^a-zA-Z0-9]/g, "") || "LOC";
    const date = new Date().toLocaleDateString("en-CA");

    const currentTime = new Date()
      .toLocaleTimeString("en-US", { hour12: false })
      .substring(0, 5)
      .replace(/:/g, ".");

    const username = localStorage.getItem("username") || "USR";
    const usernameInitials = username.slice(0, 3).toUpperCase();

    return `${sanitizedLocation
      .substring(0, 3)
      .toUpperCase()}_${date}_${currentTime}_${usernameInitials}`;
  };

  const handleConfirm = async (e) => {
    setShowModal(false);
    await saveFormDataToDesktop(e);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleOpenModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const downloadPDF = () => {
    setLoading(true);

    // Get the form content
    const formContent = document.querySelector("#form-content");

    // Clone the form content to avoid modifying the actual DOM
    const clonedContent = formContent.cloneNode(true);

    // Remove the loader from the cloned content
    const loaderElement = clonedContent.querySelector(".loader-overlay");
    if (loaderElement) {
      loaderElement.remove();
    }

    const options = {
      margin: 10,
      filename: "form.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 5 },
      jsPDF: { unit: "mm", format: "a2", orientation: "landscape" },
    };

    // Convert the cloned content to PDF
    html2pdf()
      .from(clonedContent)
      .set(options)
      .outputPdf("blob")
      .then((pdfBlob) => {
        const pdfURL = URL.createObjectURL(pdfBlob);
        setLoading(false); // Hide loader after completion
        window.open(pdfURL, "_blank");
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error generating PDF:", error);
      });
  };

  const filterFormWithAutoEntry = () => {
    if (formFields) {
      const groupedByDate = formFields.reduce((acc, data) => {
        const date = data?.physical_count_date;

        if (!acc[date]) {
          acc[date] = [];
        }

        acc[date].push(data);
        return acc;
      }, {});

      const groupedArray = Object.entries(groupedByDate).map(
        ([date, data]) => ({
          label: date,
          value: data,
        })
      );
      if (groupedArray) {
        handleDownloadFolder(groupedArray);
      } else {
        setLoading(false);
        toast.error("please try again!");
      }
    }
  };

  const downloadFolder = () => {
    filterFormWithAutoEntry();
  };

  const handleDownloadFolder = (data) => {
    setLoading(true);
    const zip = new JSZip();

    data[0]?.value?.forEach((entries) => {
      console.log(entries, "entries");
      console.log(entries[0], "entries[0]");
      const entry = entries;
      const folderName = entry?.auto_entry_no;
      const folder = zip.folder(folderName);

      // 1. Prepare Serializable Data for Excel
      const serializableData = { ...entry };

      delete serializableData.files;
      delete serializableData.tabledata;

      // Handle `document` field
      if (entry.document) {
        folder.file(
          `${entry.document.name.replace(
            /\.[^/.]+$/,
            ""
          )}_${entry.auto_entry_no.slice(-8)}${entry.document.name.slice(
            entry.document.name.lastIndexOf(".")
          )}`,
          entry.document
        );
        serializableData.document = `${entry.document.name.replace(
          /\.[^/.]+$/,
          ""
        )}_${entry.auto_entry_no.slice(-8)}${entry.document.name.slice(
          entry.document.name.lastIndexOf(".")
        )}`;
      }

      // Handle `client_signature`
      if (entry.client_signature) {
        folder.file(
          `client_${entry.client_signature.name.replace(
            /\.[^/.]+$/,
            ""
          )}_${entry.auto_entry_no.slice(
            -8
          )}${entry.client_signature.name.slice(
            entry.client_signature.name.lastIndexOf(".")
          )}`,
          entry.client_signature
        );
        serializableData.client_signature = `client_${entry.client_signature.name.replace(
          /\.[^/.]+$/,
          ""
        )}_${entry.auto_entry_no.slice(-8)}${entry.client_signature.name.slice(
          entry.client_signature.name.lastIndexOf(".")
        )}`;
      }

      // Handle `auditor_signature`
      if (entry.auditor_signature) {
        folder.file(
          `auditor_${entry.auditor_signature.name.replace(
            /\.[^/.]+$/,
            ""
          )}_${entry.auto_entry_no.slice(
            -8
          )}${entry.auditor_signature.name.slice(
            entry.auditor_signature.name.lastIndexOf(".")
          )}`,
          entry.auditor_signature
        );
        serializableData.auditor_signature = `auditor_${entry.auditor_signature.name.replace(
          /\.[^/.]+$/,
          ""
        )}_${entry.auto_entry_no.slice(-8)}${entry.auditor_signature.name.slice(
          entry.auditor_signature.name.lastIndexOf(".")
        )}`;
      }

      // Handle `images` array
      if (entry.images && Array.isArray(entry.images)) {
        const imageNames = entry.images.map((imgObj, index) => {
          if (imgObj.file) {
            folder.file(
              `${index + 1}.${imgObj.file.name.replace(
                /\.[^/.]+$/,
                ""
              )}_${entry.auto_entry_no.slice(-8)}${imgObj.file.name.slice(
                imgObj.file.name.lastIndexOf(".")
              )}`,
              imgObj.file
            );
            return `${index + 1}.${imgObj.file.name.replace(
              /\.[^/.]+$/,
              ""
            )}_${entry.auto_entry_no.slice(-8)}${imgObj.file.name.slice(
              imgObj.file.name.lastIndexOf(".")
            )}`;
          }
          return `${index + 1}_Unknown`;
        });

        serializableData.images = imageNames.join(", ");
      }

      // Handle `tabledata` array for detailed rows
      let tableDataRows = [];

      if (entry.tabledata && Array.isArray(entry.tabledata)) {
        entry.tabledata.forEach((tableEntry, index) => {
          const tableDataSerializable = { ...tableEntry };

          // Handle `image` field in tabledata
          if (tableEntry.image && tableEntry.image.name) {
            folder.file(
              `${tableEntry.image.name.replace(
                /\.[^/.]+$/,
                ""
              )}_${entry.auto_entry_no.slice(-8)}${tableEntry.image.name.slice(
                tableEntry.image.name.lastIndexOf(".")
              )}`,
              tableEntry.image
            );
            tableDataSerializable.image = `${tableEntry.image.name.replace(
              /\.[^/.]+$/,
              ""
            )}_${entry.auto_entry_no.slice(-8)}${tableEntry.image.name.slice(
              tableEntry.image.name.lastIndexOf(".")
            )}`;
          }

          // Handle `image1` field in tabledata
          if (tableEntry.image1 && tableEntry.image1.name) {
            folder.file(
              `${tableEntry.image1.name.replace(
                /\.[^/.]+$/,
                ""
              )}_${entry.auto_entry_no.slice(-8)}${tableEntry.image1.name.slice(
                tableEntry.image1.name.lastIndexOf(".")
              )}`,
              tableEntry.image1
            );
            tableDataSerializable.image1 = `${tableEntry.image1.name.replace(
              /\.[^/.]+$/,
              ""
            )}_${entry.auto_entry_no.slice(-8)}${tableEntry.image1.name.slice(
              tableEntry.image1.name.lastIndexOf(".")
            )}`;
          }

          // Add serialized tabledata row
          tableDataRows.push({
            ...serializableData, // Parent level data for reference
            ...tableDataSerializable, // Child table data details
          });
        });
      }

      // const formContent = document.querySelector("#form-content"); // Assuming `form-content` is the entry
      // // Clone the form content to avoid modifying the actual DOM
      // const clonedContent = formContent.cloneNode(true);

      // // Remove the loader from the cloned content
      // const loaderElement = clonedContent.querySelector(".loader-overlay");
      // if (loaderElement) {
      //   loaderElement.remove();
      // }
      // const pdfBlob = html2pdf()
      //   .from(formContent)
      //   .set({
      //     margin: 10,
      //     filename: `${folderName}_preview-form.pdf`,
      //     image: { type: "jpeg", quality: 0.98 },
      //     html2canvas: { scale: 5 },
      //     jsPDF: { unit: "mm", format: "a2", orientation: "landscape" },
      //   })
      //   .outputPdf("blob");

      // Clone form content and remove loader before PDF generation
      const formContent = document.querySelector("#form-content");
      const clonedContent = formContent.cloneNode(true);

      // Hide the loader before generating the PDF
      const loaderElement = clonedContent.querySelector(".loader-overlay");
      if (loaderElement) {
        loaderElement.style.display = "none";
      }

      // Generate PDF without loader
      const pdfBlob = html2pdf()
        .from(clonedContent)
        .set({
          margin: 10,
          filename: "preview-form.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 5 },
          jsPDF: { unit: "mm", format: "a2", orientation: "landscape" },
        })
        .outputPdf("blob");

      folder.file(`preview-form_${entry.auto_entry_no.slice(-8)}.pdf`, pdfBlob);

      // Add Excel for `tabledata`
      const worksheet = XLSX.utils.json_to_sheet(
        tableDataRows.length > 0 ? tableDataRows : [serializableData]
      );
      const workbook = {
        Sheets: { Sheet1: worksheet },
        SheetNames: ["Sheet1"],
      };
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      folder.file(`Excel_${entry.auto_entry_no.slice(-8)}.xlsx`, excelBuffer);
    });

    // Generate ZIP File
    zip.generateAsync({ type: "blob" }).then((content) => {
      const now = new Date();
      const formattedDate = now.toISOString().split("T")[0]; // YYYY-MM-DD
      const formattedTime = now.toTimeString().split(" ")[0].replace(/:/g, "-"); // HH-MM-SS

      saveAs(content, `${formattedDate}_${formattedTime}.zip`);
      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    });
  };

  return (
    <>
      <LandingPage />
      <div className="p-0 form-wrapper">
        <div className="py-0 container">
          <div className="form-container" id="form-content">
            {formFields?.map((form, index) => (
              <>
                <div className="client-section">
                  <div className="">
                    <div className="client-section-first form-group">
                      <label>FORM TYPE</label>
                    </div>
                    <div key={index} className="client-select-second">
                      <Select
                        options={typeOptions}
                        onChange={(selectedOption) =>
                          handleSelectChange(index, "formType", selectedOption)
                        }
                        value={typeOptions.find(
                          (option) => option.value === formFields[index]?.client
                        )}
                        isClearable
                        style={{ width: "400px" }}
                      />
                    </div>
                    {formFields[0].formType === "Recount" ||
                    formFields[0].formType === "Cancelled/Re-entered" ||
                    formFields[0].formType === "Against Variance" ? (
                      <div className="p-0 my-2">
                        <input
                          type="text"
                          className="form-control"
                          name="previous_aen"
                          placeholder="previous auto entry no..."
                          value={formFields[0].previous_aen || ""}
                          onChange={(e) => handleInputChange(e, 0)}
                        />
                      </div>
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <div>
                    <div className="client-section-first form-group">
                      <label>SELECT YOUR CLIENT</label>
                    </div>
                    <div key={index} className="client-select-second">
                      <Select
                        options={clientOptions}
                        onChange={(selectedOption) =>
                          handleSelectChange(index, "client", selectedOption)
                        }
                        value={clientOptions.find(
                          (option) => option.value === formFields[index]?.client
                        )}
                        isClearable
                        style={{ width: "400px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-physical-stock">
                  <h3 className="physical-stock-heading">
                    {" "}
                    Physical stock verification sheet{" "}
                  </h3>

                  <div className="physical-stock-sheet-outer">
                    <div>
                      <div
                        className="physical-stock-header"
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        style={{ cursor: "pointer" }}
                      >
                        <h2>Enter The Details</h2>
                        <div className="icon-dropdown-first">
                          <i
                            className={`bi ${
                              isCollapsed ? "bi-chevron-up" : "bi-chevron-down"
                            }`}
                          ></i>
                        </div>
                      </div>
                      {isCollapsed && (
                        <div className="physical-stock-main-content">
                          <div className="physical-stock-content-1">
                            <div>
                              <div className="nsi-checkbox">
                                <span className="m-0 toggle-label">
                                  <label className="">NON STOCK ITEMS</label>
                                </span>
                                <span
                                  className="mt-0 toggle-box"
                                  onClick={(e) => handleToggle(e)}
                                >
                                  {isChecked && (
                                    <span className="checkmark">✔️</span>
                                  )}
                                </span>
                              </div>

                              {isChecked && (
                                <div className="p-0 mb-3 form-group nsi-input">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="nsi"
                                    value={formFields[0]?.nsi || ""}
                                    readOnly
                                  />
                                </div>
                              )}
                            </div>

                            <div className="row">
                              <>
                                {!isChecked ? (
                                  <div className="col-6 col-md-4">
                                    <div className="p-0 form-group">
                                      <label>SAP CODE/ITEM NO</label>
                                      <Select
                                        options={sapData}
                                        value={
                                          formFields[0]?.sap_code_or_item_no
                                            ? sapData?.find(
                                                (option) =>
                                                  option.value ===
                                                  formFields[0]
                                                    .sap_code_or_item_no
                                              ) || null
                                            : null
                                        }
                                        isClearable
                                        isDisabled={isChecked}
                                        onChange={(selectedOption) =>
                                          handleItemNoSelect(selectedOption)
                                        }
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <span></span>
                                )}
                              </>

                              {formFields.map((field, index) => (
                                <div key={index} className="col-6 col-md-4">
                                  <div className="p-0 form-group">
                                    <label>DATE</label>
                                    <input
                                      className="form-control"
                                      type="date"
                                      name={`date-${index}`}
                                      value={field.physical_count_date || ""}
                                      onChange={(event) =>
                                        setFormFields((prev) => {
                                          const updated = [...prev];
                                          updated[index].date =
                                            event.target.value;
                                          return updated;
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              ))}

                              <div className="col-6 col-md-4">
                                <div className="p-0 form-group">
                                  <label>AUTO ENTRY NO</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="auto_entry_no"
                                    value={formFields[0].auto_entry_no || ""}
                                    onChange={(e) => handleInputChange(e, 0)}
                                  />
                                </div>
                              </div>

                              {!isChecked ? (
                                <div className="col-12 col-md-12">
                                  <div className="p-0 form-group1-description">
                                    <label htmlFor="description">
                                      Description
                                    </label>
                                    <Select
                                      options={
                                        descriptionData
                                          ? descriptionData.map((item) => ({
                                              value: item.value,
                                              label: item.label,
                                            }))
                                          : []
                                      }
                                      value={
                                        descriptionData &&
                                        formFields[0]?.description
                                          ? descriptionData.find(
                                              (option) =>
                                                option.value ===
                                                formFields[0]?.description
                                            ) || null
                                          : null
                                      }
                                      isClearable
                                      onChange={handleDescription}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="col-6 col-md-4">
                                  <div className="p-0 form-group">
                                    <label>DESCRIPTION</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="description"
                                      value={formFields[0].description || ""}
                                      onChange={(e) => handleInputChange(e, 0)}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          <hr className="form-line" />

                          <div className="row">
                            <div className="col-6 col-md-4">
                              <div className="p-0 form-group">
                                <label>ITEM GROUP NAME</label>
                                <Select
                                  options={itemGroup}
                                  onChange={
                                    (selectedOption) =>
                                      handleSelectChange(
                                        0,
                                        "item_group_name",
                                        selectedOption
                                      ) // Specify the index
                                  }
                                  isClearable
                                  value={
                                    itemGroup?.find(
                                      (option) =>
                                        option.value ===
                                        formFields[0]?.item_group_name
                                    ) || null
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-6 col-md-4">
                              <div className="p-0 form-group">
                                <label>ITEM SUB-GROUP 2</label>
                                <Select
                                  options={group_2}
                                  onChange={(selectedOption) =>
                                    handleSelectChange(
                                      0,
                                      "item_sub_group_2",
                                      selectedOption
                                    )
                                  }
                                  isClearable
                                  value={
                                    group_2?.find(
                                      (option) =>
                                        option.value ===
                                        formFields[0]?.item_sub_group_2
                                    ) || null
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-6 col-md-4">
                              <div className="p-0 form-group">
                                <label>ITEM SUB-GROUP 3</label>
                                <Select
                                  options={subgroup_3}
                                  onChange={(selectedOption) =>
                                    handleSelectChange(
                                      0,
                                      "item_sub_group_3",
                                      selectedOption
                                    )
                                  }
                                  isClearable
                                  value={
                                    subgroup_3?.find(
                                      (option) =>
                                        option.value ===
                                        formFields[0]?.item_sub_group_3
                                    ) || null
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-6 col-md-4">
                              <div className="p-0 form-group">
                                <label>DEPARTMENT NAME</label>
                                <Select
                                  options={departmentOptions}
                                  onChange={(selectedOption) =>
                                    handleSelectChange(
                                      index,
                                      "department_name",
                                      selectedOption
                                    )
                                  }
                                  value={clientOptions.find(
                                    (option) =>
                                      option.value ===
                                      formFields[index]?.department_name
                                  )}
                                  isClearable
                                />
                              </div>
                            </div>

                            <div className="col-6 col-md-4">
                              <div className="p-0 form-group">
                                <label>STORAGE NAME</label>{" "}
                                {/* changed from shelf name */}
                                <input
                                  type="text"
                                  className="form-control"
                                  name="storage_name"
                                  value={formFields[0].storage_name || ""}
                                  onChange={(e) => handleInputChange(e, 0)}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-6 col-md-4">
                              <div className="p-0 form-group">
                                <label>LOCATION</label>
                                <Select
                                  options={locationOptions}
                                  onChange={(selectedOption) => {
                                    const updatedLocation =
                                      selectedOption?.value || "";
                                    const uniqueId =
                                      generateUniqueId(updatedLocation);

                                    // Update formFields
                                    setFormFields((prevFields) =>
                                      prevFields.map((field, idx) =>
                                        idx === index
                                          ? {
                                              ...field,
                                              location: updatedLocation,
                                              auto_entry_no: uniqueId,
                                            }
                                          : field
                                      )
                                    );

                                    // Update tableLocation
                                    setTableLocation(updatedLocation);
                                  }}
                                  value={locationOptions.find(
                                    (option) =>
                                      option.value ===
                                      formFields[index]?.location
                                  )}
                                  isClearable
                                />
                              </div>
                            </div>
                          </div>

                          <hr className="form-line"></hr>

                          <div className="row">
                            <div className="col-6 col-md-4">
                              <div className="p-0 form-group">
                                <label>FLOOR</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="floor"
                                  value={formFields[0].floor || ""}
                                  onChange={(e) => handleInputChange(e, 0)}
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-6 col-md-4">
                              <div className="p-0 form-group">
                                <label>AISLE NAME</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="aisle_name"
                                  value={formFields[0].aisle_name || ""}
                                  onChange={(e) => handleInputChange(e, 0)}
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-6 col-md-4">
                              <div className="p-0 form-group">
                                <label>COMPARTMENT</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="compartment_name"
                                  value={formFields[0].compartment_name || ""}
                                  onChange={(e) => handleInputChange(e, 0)}
                                />
                              </div>
                            </div>

                            <div className="col-6 col-md-4">
                              <div className="p-0 form-group">
                                <label>TEAM NAME</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="team_name"
                                  value={`${localStorage.getItem(
                                    "team name"
                                  )} - ${localStorage.getItem(
                                    "username"
                                  )}`.replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  )}
                                />
                              </div>
                            </div>

                            <div className="col-6 col-md-4">
                              <div className="p-0 form-group">
                                <label>CLIENT TEAM NAME</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="client_team_name"
                                  value={formFields[0].client_team_name || ""}
                                  onChange={(e) => handleInputChange(e, 0)}
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-6 col-md-4">
                              <div className="mixbox-checkbox">
                                <span className="m-0 toggle-label">
                                  <label className="mb-0">MIXBOX</label>
                                </span>
                                <span
                                  className="mt-0 toggle-box"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsMixboxChecked((prev) => !prev);
                                  }}
                                >
                                  {isMixboxChecked && (
                                    <span className="checkmark">✔️</span>
                                  )}
                                </span>
                                {isMixboxChecked && (
                                  <div className="p-0 form-group mixbox-input">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="mixbox"
                                      value={formFields[0].mixbox || ""}
                                      onChange={(e) =>
                                        handleMixboxChange(
                                          index,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="custom-field-section">
                    <div
                      className="physical-stock-header"
                      onClick={() => setIsCustomCollapsed(!isCustomCollapsed)}
                      style={{ cursor: "pointer" }}
                    >
                      <h2>
                        Add Table{" "}
                        {sapCodeShow ? (
                          <span
                            style={{
                              fontSize: "14px",
                              marginLeft: "17px",
                              fontWeight: "normal",
                            }}
                          >
                            SAP CODE: {sapCodeShow ? sapCodeShow : ""}
                          </span>
                        ) : (
                          ""
                        )}{" "}
                        {descriptionShow ? (
                          <span
                            style={{
                              fontSize: "14px",
                              marginLeft: "17px",
                              fontWeight: "normal",
                            }}
                          >
                            DESCRIPTION:{" "}
                            {descriptionShow ? descriptionShow : ""}
                          </span>
                        ) : (
                          ""
                        )}
                      </h2>
                      <div className="icon-dropdown-first">
                        <i
                          className={`bi ${
                            isCustomCollapsed
                              ? "bi-chevron-up"
                              : "bi-chevron-down"
                          }`}
                        ></i>
                      </div>
                    </div>

                    {isCustomCollapsed && (
                      <DynamicTableForDistributor
                        onTableDataChange={handleTableDataChange}
                        uom={uom}
                        tableLocation={tableLocation}
                      />
                    )}
                  </div>

                  <div className="photos-upload-section">
                    <div
                      className="photos-upload-header"
                      onClick={() => setIsPhotosCollapsed(!isPhotosCollapsed)}
                      style={{ cursor: "pointer" }}
                    >
                      <span>
                        {" "}
                        <h3>Upload Photos</h3>
                        <h2 className="mt-1">
                          (Max. File size: 25 MB and it must be .jpeg, .png)
                        </h2>
                      </span>

                      <div className="icon-dropdown-second">
                        <i
                          className={`bi ${
                            isPhotosCollapsed
                              ? "bi-chevron-up"
                              : "bi-chevron-down"
                          }`}
                        ></i>
                      </div>
                    </div>
                    {isPhotosCollapsed && (
                      <div className="photos-upload">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="w-100 upload-photo">
                              <div className="upload-photo-text">
                                <h2>Click to Upload</h2>
                                <span>(Max. File size: 25 MB)</span>
                              </div>
                              <div className="upload-photo-image">
                                <label
                                  className="mb-0"
                                  style={{ cursor: "pointer" }}
                                >
                                  <i className="bi bi-file-earmark-arrow-up"></i>
                                  <input
                                    type="file"
                                    accept="image/jpeg, image/png"
                                    onChange={(event) =>
                                      handleFileChange(index, event)
                                    }
                                    multiple
                                    style={{ display: "none" }}
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div
                              className="w-100 open-camera"
                              style={{ cursor: "pointer" }}
                            >
                              <div className="open-camera-text">
                                <h2>Open Camera</h2>
                                <span>(Make sure to allow camera access!)</span>
                              </div>
                              <div className="open-camera-image">
                                <i
                                  className="bi bi-camera"
                                  onClick={handleOpenCamera}
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="photos-preview">
                      {Array.isArray(formFields[index]?.images) &&
                      formFields[index]?.images.length > 0 ? (
                        formFields[index]?.images.map((photo, photoIndex) => (
                          <div
                            key={photo.id}
                            className="preview-photo-container"
                          >
                            <img
                              src={photo.image} // Access the image URL or base64 data
                              alt={`Uploaded ${photoIndex}`}
                              className="preview-photo"
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                            />
                            <button
                              type="button"
                              onClick={() => deletePhoto(index, photoIndex)}
                              className="delete-photo-button"
                            >
                              <i className="bi bi-x" />
                            </button>
                          </div>
                        ))
                      ) : (
                        <span></span>
                      )}
                    </div>

                    {stream && (
                      <div className="container-camera-capture">
                        <div className="video-container">
                          <video
                            ref={videoRef}
                            className="video"
                            autoPlay
                            playsInline
                          />
                          <button
                            onClick={() => handleCapturePhoto(index)}
                            className="capture-button"
                          >
                            <i class="bi bi-camera-fill"></i>
                          </button>
                          <button
                            onClick={handleCloseCamera}
                            className="close-button"
                          >
                            <i class="bi bi-x-circle"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="photos-upload-section">
                    <div
                      className="photos-upload-header"
                      onClick={() =>
                        setIsDocumentsCollapsed(!isDocumentsCollapsed)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <span>
                        {" "}
                        <h3>Upload Documents</h3>
                        <h2 className="mt-1">
                          (Max. File size: 25 MB and it must be .pdf)
                        </h2>
                      </span>

                      <div className="icon-dropdown-second">
                        <i
                          className={`bi ${
                            isDocumentsCollapsed
                              ? "bi-chevron-up"
                              : "bi-chevron-down"
                          }`}
                        ></i>
                      </div>
                    </div>
                    {isDocumentsCollapsed && (
                      <div className="photos-upload">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="w-100 upload-photo">
                              <div className="upload-photo-text">
                                <h2>Click to Upload</h2>
                                <span>(Max. File size: 25 MB)</span>
                              </div>
                              <div className="upload-photo-image">
                                <label
                                  className="mb-0"
                                  style={{ cursor: "pointer" }}
                                >
                                  <i className="bi bi-file-earmark-arrow-up"></i>
                                  <input
                                    type="file"
                                    accept="image/jpeg, image/png"
                                    onChange={(event) =>
                                      handleFileChangeForDocuments(index, event)
                                    }
                                    multiple
                                    style={{ display: "none" }}
                                  />
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-6">
                            <div className="w-100 open-camera">
                              <div className="open-camera-text">
                                <h2>Open camera</h2>
                                <span>(Make sure to allow camera access!)</span>
                              </div>
                              <div
                                className="open-camera-image"
                                style={{ cursor: "pointer" }}
                              >
                                <i
                                  className="bi bi-camera"
                                  onClick={handleOpenCameraForDocuments}
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        {disablePdfGenerateButton && (
                          <div className="generatepdf">
                            <button
                              onClick={() => handleGeneratePDF(index)}
                              className="generate-pdf-button"
                            >
                              Generate PDF
                            </button>
                          </div>
                        )}
                      </div>
                    )}

                    <div className="photos-preview">
                      {Array.isArray(formFields[index]?.documents) &&
                        formFields[index].documents.map((photo, photoIndex) => (
                          <div
                            key={photoIndex}
                            className="preview-photo-container"
                          >
                            <img
                              src={photo}
                              alt={`Uploaded ${photoIndex}`}
                              className="preview-photo"
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                            />
                            <button
                              type="button"
                              onClick={() => deleteDocuments(index, photoIndex)}
                              className="delete-photo-button"
                            >
                              <i className="bi bi-x" />
                            </button>
                          </div>
                        ))}
                    </div>

                    {documentsStream && (
                      <div className="container-camera-capture">
                        <div className="video-container">
                          <video
                            ref={documentsVideoRef}
                            className="video"
                            autoPlay
                            playsInline
                          />
                          <button
                            onClick={() => handleCaptureDocuments(index)}
                            className="capture-button"
                          >
                            <i class="bi bi-camera-fill"></i>
                          </button>
                          <button
                            onClick={handleCloseCameraForDocuments}
                            className="close-button"
                          >
                            <i class="bi bi-x-circle"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="photos-upload-section-signature">
                    <div
                      className="photos-upload-header-signature"
                      onClick={() =>
                        setIsSignaturesCollapsed(!isSignaturesCollapsed)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <h2>Signatures</h2>
                      <div className="icon-dropdown-second">
                        <i
                          className={`bi ${
                            isSignaturesCollapsed
                              ? "bi-chevron-up"
                              : "bi-chevron-down"
                          }`}
                        ></i>
                      </div>
                    </div>
                    {isSignaturesCollapsed && (
                      <div className="pt-0 photos-upload">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <Signature
                              title="Client Signature"
                              signatureIndex={0}
                              setFormFields={setFormFields}
                              formFields={formFields}
                              fieldName="client_sign_name"
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <Signature
                              title="Auditor Signature"
                              signatureIndex={1}
                              setFormFields={setFormFields}
                              formFields={formFields}
                              fieldName="auditor_sign_name"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ))}
            <div className="form-footer">
              <div className="form-footer-content">
                <div className="auto-saved"></div>
                <div className="form-footer-btn">
                  <button
                    type="submit"
                    className="mt-0 btn footer-btn download-btn"
                    onClick={downloadPDF}
                  >
                    <i className="bi bi-download"></i> Preview
                  </button>
                  <button
                    type="submit"
                    className="btn mt-0 footer-btn submit-btn"
                    onClick={(e) => handleOpenModal(e)}
                    disabled={showDownloadFolder}
                  >
                    Submit
                  </button>
                  <Loader show={loading} />
                  {showDownloadFolder ? (
                    <>
                      <button
                        type="submit"
                        className="mt-0 btn footer-btn download-btn"
                        onClick={downloadFolder}
                      >
                        <i className="bi bi-download"></i> Folder
                      </button>
                      <Loader show={loading} />
                    </>
                  ) : (
                    <span></span>
                  )}
                </div>
              </div>
              <ToastContainer />
              {/* Confirmation Modal */}
              <Modal show={showModal} onHide={handleCancel}>
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to submit this form?
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={handleConfirm}>
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
