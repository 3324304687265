import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { DistributorLogin } from "./container/Distributor/authentication/login";
import { FormForDistributor } from "./container/Distributor/form/distributorForm";
import { AdminLandingPage } from "./container/Admin/admin_landing_page";
import { ReportPage } from "./container/Distributor/report/index";
import ProtectedRoute from "./services/protectedRoutes";
import { LandingPage } from "./container/Distributor/landing";
import { MasterFile } from "./container/Distributor/masterFile";
import { DayReport } from "./container/Distributor/report/dayReport";
import { AdminMasterFile } from "./container/Admin/masterFile";
import { Checkvariance } from "./container/Admin/variance/checkvariance";
import { AdminReportPage } from "./container/Admin/report";
import { TeamList } from "./container/Admin/team/teamList";
import { AdminForm } from "./container/Admin/form/adminForm";
import { AdminDayReport } from "./container/Admin/report/adminDayReport";

function App() {
  const [userRole, setUserRole] = useState(() => localStorage.getItem("userRole") || "");

  useEffect(() => {
    // This effect can be removed if you are directly updating the state in the login component
    const handleStorageChange = () => {
      const user = localStorage.getItem("userRole");
      setUserRole(user);
    }
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Route */}
        <Route path="/" element={<DistributorLogin setUserRole={setUserRole} />} />

        {/* Auditor Routes */}
        <Route
          path="/form"
          element={
            <ProtectedRoute
              element={<FormForDistributor />}
              condition={userRole === "Auditor"}
            />
          }
        />
        <Route
          path="/landing-page"
          element={
            <ProtectedRoute
              element={<LandingPage />}
              condition={userRole === "Auditor"}
            />
          }
        />
        <Route
          path="/master-file"
          element={
            <ProtectedRoute
              element={<MasterFile />}
              condition={userRole === "Auditor"}
            />
          }
        />
        <Route
          path="/day-report"
          element={
            <ProtectedRoute
              element={<DayReport />}
              condition={userRole === "Auditor"}
            />
          }
        />
        <Route
          path="/report-page"
          element={
            <ProtectedRoute
              element={<ReportPage />}
              condition={userRole === "Auditor"}
            />
          }
        />

        {/* Admin Routes */}
        <Route
          path="/admin-form"
          element={
            <ProtectedRoute
              element={<AdminForm />}
              condition={userRole === "Admin"}
            />
          }
        />
        <Route
          path="/admin-landing-page"
          element={
            <ProtectedRoute
              element={<AdminLandingPage />}
              condition={userRole === "Admin"}
            />
          }
        />
          <Route
          path="/admin-master-file"
          element={
            <ProtectedRoute
              element={<AdminMasterFile />}
              condition={userRole === "Admin"}
            />
          }
        />
             <Route
          path="/variance"
          element={
            <ProtectedRoute
              element={<Checkvariance />}
              condition={userRole === "Admin"}
            />
          }
        />
             <Route
          path="/admin-report-page"
          element={
            <ProtectedRoute
              element={<AdminReportPage />}
              condition={userRole === "Admin"}
            />
          }
        />

<Route
          path="/admin-day-report"
          element={
            <ProtectedRoute
              element={<AdminDayReport />}
              condition={userRole === "Admin"}
            />
          }
        />
               <Route
          path="/team-list"
          element={
            <ProtectedRoute
              element={<TeamList />}
              condition={userRole === "Admin"}
            />
          }
        />

        {/* Catch-all Route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
