import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const DistributorLogin = ({ setUserRole }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const validCredentials = [
    { username: 'kunal shah', password: 'kunal', team: 'Team 1' },
    { username: 'raj Chotai', password: 'raj', team: 'Team 2' },
    { username: 'abhay Sheth', password: 'abhay', team: 'Team 3' },
    { username: 'shivkumar Sharma', password: 'shivkumar', team: 'Team 4' },
    { username: 'raj Padmani', password: 'raj', team: 'Team 5' },

    { username: 'harsh taily', password: 'harsh', team: 'Team 7' },
    { username: 'ravi nagrecha', password: 'ravi', team: 'Team 8' },
    { username: 'bot', password: 'bot', team: 'Team 9' },
  ];

  const adminCredentials = [
    { username: 'manthan', password: 'manthan', team: 'Team 1' },
    { username: 'chirag mehta', password: 'chirag', team: 'Team 2' },
    { username: 'meet shah', password: 'meet', team: 'Team 3' },
    { username: 'vinit', password: 'vinit', team: 'Team 4' },
    { username: 'arpit', password: 'arpit', team: 'Team 5' },
  ]

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const user = validCredentials.find(
      (cred) => cred.username === username && cred.password === password
    );
    
    const isAdmin = adminCredentials.find(
      (admin) => admin.username === username && admin.password === password
    );

    if (user || isAdmin) {
      const role = isAdmin ? 'Admin' : 'Auditor';
      localStorage.setItem('userRole', role);
      localStorage.setItem('username', username);
      localStorage.setItem('team name', isAdmin? isAdmin.team : user.team);

      setUserRole(role); 
    
      if (isAdmin) {
        navigate('/admin-form');
      } else {
        navigate('/form');
      }
    } else {
      setError("Invalid username or password");
    }
    
  };
  
  return (
    <div className="container">
  <form className="login-form" onSubmit={handleSubmit}>
    <h2 className="login-title">Login</h2>

    <div className="input-group">
      <i className="bi bi-person icon"></i>
      <input
        type="text"
        className="login-input"
        placeholder="Enter Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        required
      />
    </div>

    <div className="input-group">
      <i className="bi bi-key icon"></i>
      <input
        type="password"
        className="login-input"
        placeholder="Enter Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
    </div>

    <button type="submit" className="login-button">Login</button>
    {error && <p className="error-message">{error}</p>}
  </form>
</div>

  );
};
