import React from 'react'
import { AdminLandingPage } from '../admin_landing_page'

export const Checkvariance = () => {
  return (
    <>
      <AdminLandingPage />
      <div>checkvariance4444</div>
    </>
  
  )
}