import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "./admin_form.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const Signature = ({
  title,
  signatureIndex,
  setFormFields,
  formFields,
  fieldName,
}) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const canvasRef = useRef(null);

  const handleInputChange = (e) => {
    const value = e.target.value;

    setFormFields((prevFields) =>
      prevFields.map((field, index) => {
        if (index === 0) {
          const updatedField = { ...field };
          updatedField[fieldName] = value; // Update dynamically based on fieldName
          return updatedField;
        }
        return field;
      })
    );
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      ctx.lineWidth = 2;
      ctx.lineCap = "round";
      ctx.strokeStyle = "black";
    }
  }, []);

  const startDrawing = (e) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    setIsDrawing(true);

    const { offsetX, offsetY } = getEventPosition(e);
    ctx.beginPath();
    ctx.moveTo(offsetX, offsetY);
  };

  const draw = (e) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const { offsetX, offsetY } = getEventPosition(e);

    ctx.lineTo(offsetX, offsetY);
    ctx.stroke();
  };

  const stopDrawing = () => {
    setIsDrawing(false);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.closePath();
  };

  // Utility to get position based on event type
  const getEventPosition = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    if (e.type.startsWith("touch")) {
      const touch = e.touches[0] || e.changedTouches[0];
      return {
        offsetX: touch.clientX - rect.left,
        offsetY: touch.clientY - rect.top,
      };
    } else {
      return {
        offsetX: e.nativeEvent.offsetX,
        offsetY: e.nativeEvent.offsetY,
      };
    }
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const saveSignature = async () => {
    const canvas = canvasRef.current;

    if (isEmptyCanvas(canvas)) {
      // alert("Please draw a signature before saving.");
      toast.error("Please draw a signature before saving.");
      return;
    }

    const signatureImage = canvas.toDataURL("image/png");
    const blob = dataURLToBlob(signatureImage);
    const file = new File([blob], "signature.png", { type: blob.type });

    setFormFields((prevFields) =>
      prevFields.map((field, index) => {
        if (index === 0) {
          const updatedField = { ...field };
          if (signatureIndex === 0) {
            updatedField.client_signature = file;
          } else if (signatureIndex === 1) {
            updatedField.auditor_signature = file;
          }
          return updatedField;
        }
        return field;
      })
    );
    toast.success("Signature saved successfully!");
  };

  const isEmptyCanvas = (canvas) => {
    const context = canvas.getContext("2d");
    const pixelData = context.getImageData(
      0,
      0,
      canvas.width,
      canvas.height
    ).data;
    return !pixelData.some((value) => value !== 0);
  };

  const dataURLToBlob = (dataURL) => {
    const [header, base64] = dataURL.split(",");
    const mimeType = header.match(/:(.*?);/)[1];
    const binary = atob(base64);
    const array = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      array[i] = binary.charCodeAt(i);
    }
    return new Blob([array], { type: mimeType });
  };

  return (
    <div className="signature-container">
      <h3 className="ff-jakarta signature-title">{title}</h3>
      <div className="canvas-wrapper">
        <canvas
          ref={canvasRef}
          width="400"
          height="200"
          className="signature-canvas"
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={stopDrawing}
          onMouseLeave={stopDrawing}
          onTouchStart={(e) => {
            e.preventDefault(); // Prevent scrolling on touch start
            startDrawing(e);
          }}
          onTouchMove={(e) => {
            e.preventDefault(); // Prevent scrolling while drawing
            draw(e);
          }}
          onTouchEnd={(e) => {
            e.preventDefault(); // Ensure touch interactions end smoothly
            stopDrawing();
          }}></canvas>
      </div>
      <div className="p-0 my-3 form-group">
        <input
          type="text"
          className="form-control"
          placeholder="enter name here.."
          value={formFields[0]?.[fieldName] || ""}
          onChange={(e) => handleInputChange(e)}
        />
      </div>

      <div className="buttons-container d-flex justify-content-around">
        <div>
          <button onClick={saveSignature} className="save-btn">
            Save
          </button>
          <button onClick={clearCanvas} className="clear-btn">
            Clear
          </button>
        </div>
      </div>
    </div>
  );
};

export default Signature;
