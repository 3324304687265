import React, { useEffect, useRef, useState } from "react";

const uomOptions = [
  { value: "Bag", label: "Bag" },
  { value: "Bags", label: "Bags" },
  { value: "Bales", label: "Bales" },
  { value: "Days", label: "Days" },
  { value: "Grams", label: "Grams" },
  { value: "HA", label: "HA" },
  { value: "Hrs", label: "Hrs" },
  { value: "Kgs", label: "Kgs" },
  { value: "Kilograms", label: "Kilograms" },
  { value: "Kms", label: "Kms" },
  { value: "KG", label: "KG" },
  { value: "Ltrs", label: "Ltrs" },
  { value: "Mandays", label: "Mandays" },
  { value: "MT", label: "MT" },
  { value: "Mtrs", label: "Mtrs" },
  { value: "Pcs", label: "Pcs" },
  { value: "Pivot", label: "Pivot" },
  { value: "Pkts", label: "Pkts" },
  { value: "Set", label: "Set" },
  { value: "Tonne", label: "Tonne" },
  { value: "Tons", label: "Tons" },
  { value: "Trip", label: "Trip" },
  { value: "Others", label: "Others" },
];

export const DynamicTableForAdmin = ({
  onTableDataChange,
  uom,
  tableLocation,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [totalPhysicalQty, setTotalPhysicalQty] = useState(0);

  const [uomAuto, setUomAuto] = useState(uom || "");
  const [locationAuto, setLocationAuto] = useState(tableLocation || "");

  const videoRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(null);

  const [rows, setRows] = useState([
    {
      package_no: "",
      physical_qty: "",
      uom_physical: uom ? uom : "",
      condition: "Original",
      batch_no: "",
      expiry_date: null,
      store_name: locationAuto ? locationAuto : "",
      auditor_observation_class: "",
      auditor_observation: "",
      mansa_remarks: "",
      senior_remarks: "",
      image: null,
      image1: null,
      more_info_type_1: "",
      more_info_type_2: "",
      more_info_type_3: "",
      more_information: "",
    },
  ]);

  const addRow = () => {
    setRows([
      ...rows,
      {
        package_no: "",
        physical_qty: "",
        uom_physical: uomAuto ? uomAuto : "",
        condition: "Original",
        batch_no: "",
        expiry_date: null,
        store_name: locationAuto ? locationAuto : "",
        auditor_observation_class: "",
        auditor_observation: "",
        mansa_remarks: "",
        senior_remarks: "",
        image: null,
        image1: null,
        more_info_type_1: "",
        more_info_type_2: "",
        more_info_type_3: "",
        more_information: "",
      },
    ]);
  };

  // Copy row function
  const copyRow = (index) => {
    const rowToCopy = rows[index];
    const copiedRow = { ...rowToCopy };
    setRows([...rows, copiedRow]);
  };

  const columnOrder = [
    "package_no",
    "physical_qty",
    "uom_physical",
    "condition",
    "batch_no",
    "expiry_date",
    "store_name",
    "auditor_observation_class",
    "auditor_observation",
    "mansa_remarks",
    "senior_remarks",
    "image",
    "image1",
    "more_info_type_1",
    "more_info_type_2",
    "more_info_type_3",
    // "more_information",
  ];

  const [streams, setStreams] = useState(Array(rows.length).fill(null));
  const [streams1, setStreams1] = useState(Array(rows.length).fill(null));

  useEffect(() => {
    if (onTableDataChange) {
      onTableDataChange(rows, totalPhysicalQty);
    }
  }, [rows, totalPhysicalQty]);

  useEffect(() => {
    if (uom) {
      setUomAuto(uom);
    }
    if (tableLocation) {
      setLocationAuto(tableLocation);
    }
  }, [uom, tableLocation]);

  // Update rows whenever uomAuto or locationAuto changes
  useEffect(() => {
    const updatedRows = rows.map((row) => ({
      ...row,
      uom_physical: uomAuto || "",
      store_name: locationAuto || "",
    }));
    setRows(updatedRows);
  }, [uomAuto, locationAuto]);

  // const handleInputChange = (index, field, value) => {
  //   const updatedRows = [...rows];

  //   if (field === "physical_qty") {
  //     const regex = /^\d*\.?\d{0,3}$/;

  //     if (value === "" || regex.test(value)) {
  //       updatedRows[index][field] = value;
  //     }
  
  //   } else if (field === "image") {
  //     updatedRows[index][field] = value;
  //   } else if (field === "image1") {
  //     updatedRows[index][field] = value;
  //   } else {
  //     updatedRows[index][field] = value;
  //   }

  //   setRows(updatedRows);
  // };

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
  
    if (field === "physical_qty") {
      const regex = /^\d*\.?\d{0,3}$/;
  
      if (value === "" || regex.test(value)) {
        updatedRows[index][field] = value;
      }
    } else if (field === "image" || field === "image1") {
      // Add FI_ prefix to the filename if value is a file object
      if (value instanceof File) {
        const updatedFile = new File([value], `TI_${value.name}`, {
          type: value.type,
          lastModified: value.lastModified,
        });
        updatedRows[index][field] = updatedFile;
      } else {
        updatedRows[index][field] = value; // For non-file values, directly update
      }
    } else {
      updatedRows[index][field] = value;
    }
  
    setRows(updatedRows);
  };

  const calculateTotal = (updatedRows) => {
    const total = updatedRows.reduce((sum, row) => {
      return (
        sum +
        (isNaN(parseFloat(row.physical_qty)) ? 0 : parseFloat(row.physical_qty))
      );
    }, 0);
    setTotalPhysicalQty(total);
  };

  useEffect(() => {
    calculateTotal(rows);
  }, [rows]);

  const deleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };

  const handleOpenCamera = async (index) => {
    try {
      // Show the camera interface immediately
      setStreams((prevStreams) => {
        const newStreams = [...prevStreams];
        newStreams[index] = "loading"; // Placeholder value
        return newStreams;
      });

      // Request access to the camera
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });

      // Update state with the new stream
      setStreams((prevStreams) => {
        const newStreams = [...prevStreams];
        newStreams[index] = stream; // Replace placeholder with actual stream
        return newStreams;
      });

      setActiveIndex(index); // Set the active camera index

      // Attach the stream to the video element
      const videoElement = document.getElementById(`video-${index}`);
      if (videoElement) {
        videoElement.srcObject = stream; // Assign the stream to the video element's srcObject
        videoElement.onloadedmetadata = () => {
          videoElement.play(); // Start video playback
        };
      }
    } catch (error) {
      console.error("Error opening camera:", error);

      // Roll back state if camera access fails
      setStreams((prevStreams) => {
        const newStreams = [...prevStreams];
        newStreams[index] = null;
        return newStreams;
      });
    }
  };

  const handleCloseCamera = (index) => {
    console.log(`Attempting to close camera for index: ${index}`);

    const stream = streams[index]; // Retrieve the stream for the given index
    if (stream && stream.getTracks) {
      // Stop all tracks of the media stream
      const tracks = stream.getTracks();
      tracks.forEach((track) => {
        console.log(`Stopping track: ${track.id}, kind: ${track.kind}`);
        track.stop();
      });

      // Clear the stream from the state
      setStreams((prevStreams) => {
        const newStreams = [...prevStreams];
        newStreams[index] = null; // Set the stream to null
        return newStreams;
      });

      // Clear the video element's srcObject
      const videoElement = document.getElementById(`video-${index}`);
      if (videoElement) {
        videoElement.srcObject = null;
        console.log(`Cleared video srcObject for index: ${index}`);
      }

      // Reset the active index if it's the same as the closing index
      setActiveIndex((prevActiveIndex) =>
        prevActiveIndex === index ? null : prevActiveIndex
      );

      console.log(`Camera for index ${index} closed.`);
    } else {
      console.log(`No active stream found for index: ${index}`);
    }
  };

  useEffect(() => {
    return () => {
      streams.forEach((stream) => {
        if (stream && stream.getTracks) {
          stream.getTracks().forEach((track) => track.stop());
        }
      });
    };
  }, [streams]);

  const handleCapturePhoto = (index) => {
    const videoElement = document.getElementById(`video-${index}`);
    if (videoElement) {
      const canvas = document.createElement("canvas");
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        console.error("Failed to get 2D context");
        return;
      }

      ctx.drawImage(videoElement, 0, 0);
      canvas.toBlob(
        (blob) => {
          if (blob) {
            const file = new File([blob], `TI_captured_image_${Date.now()}.png`, {
              type: blob.type,
            });
            setRows((prevRows) =>
              prevRows.map((row, idx) =>
                idx === index ? { ...row, image: file } : row
              )
            );
          }
        },
        "image/png",
        0.95 // Quality factor for PNG
      );
    }
  };

  const handleOpenCamera1 = async (index) => {
    try {
      // Show the camera interface immediately
      setStreams1((prevStreams) => {
        const newStreams = [...prevStreams];
        newStreams[index] = "loading"; // Placeholder value
        return newStreams;
      });

      // Request access to the camera
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });

      // Update state with the new stream
      setStreams1((prevStreams) => {
        const newStreams = [...prevStreams];
        newStreams[index] = stream; // Replace placeholder with actual stream
        return newStreams;
      });

      setActiveIndex(index); // Set the active camera index

      // Attach the stream to the video element
      const videoElement = document.getElementById(`video-${index}`); // Use the correct ID
      if (videoElement) {
        videoElement.srcObject = stream; // Assign the stream to the video element's srcObject
        videoElement.onloadedmetadata = () => {
          videoElement.play(); // Start video playback
        };
      }
    } catch (error) {
      console.error("Error opening camera:", error);

      // Roll back state if camera access fails
      setStreams1((prevStreams) => {
        const newStreams = [...prevStreams];
        newStreams[index] = null;
        return newStreams;
      });
    }
  };

  const handleCloseCamera1 = (index) => {
    console.log(`Attempting to close camera for index: ${index}`);

    const stream = streams1[index]; // Retrieve the stream for the given index
    if (stream && stream.getTracks) {
      // Stop all tracks of the media stream
      const tracks = stream.getTracks();
      tracks.forEach((track) => {
        console.log(`Stopping track: ${track.id}, kind: ${track.kind}`);
        track.stop();
      });

      // Clear the stream from the state
      setStreams1((prevStreams) => {
        const newStreams = [...prevStreams];
        newStreams[index] = null; // Set the stream to null
        return newStreams;
      });

      // Clear the video element's srcObject
      const videoElement = document.getElementById(`video1-${index}`);
      if (videoElement) {
        videoElement.srcObject = null;
        console.log(`Cleared video srcObject for index: ${index}`);
      }

      // Reset the active index if it's the same as the closing index
      setActiveIndex((prevActiveIndex) =>
        prevActiveIndex === index ? null : prevActiveIndex
      );

      console.log(`Camera for index ${index} closed.`);
    } else {
      console.log(`No active stream found for index: ${index}`);
    }
  };

  const handleCapturePhoto1 = (index) => {
    const videoElement = document.getElementById(`video-${index}`); // Use the correct ID
    if (videoElement) {
      const canvas = document.createElement("canvas");
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        console.error("Failed to get 2D context");
        return;
      }

      // Draw the current video frame onto the canvas
      ctx.drawImage(videoElement, 0, 0);

      // Convert the canvas content to a Blob and create a File object
      canvas.toBlob(
        (blob) => {
          if (blob) {
            const file = new File([blob], `TI_captured_image_${Date.now()}.png`, {
              type: blob.type,
            });

            // Update the state to include the captured image
            setRows((prevRows) =>
              prevRows.map((row, idx) =>
                idx === index ? { ...row, image1: file } : row
              )
            );
          }
        },
        "image/png",
        0.95 // Quality factor for PNG
      );
    } else {
      console.error(`Video element with ID video-${index} not found`);
    }
  };

  useEffect(() => {
    return () => {
      streams1.forEach((stream) => {
        if (stream && stream.getTracks) {
          stream.getTracks().forEach((track) => track.stop());
        }
      });
    };
  }, [streams1]);

  const handleDeleteImage = (index) => {
    const updatedRows = [...rows];
    updatedRows[index].image = null; // Remove the image for the specific row
    setRows(updatedRows);
  };

  const handleDeleteImage1 = (index) => {
    const updatedRows = [...rows];
    updatedRows[index].image1 = null; // Remove the image for the specific row
    setRows(updatedRows);
  };

  const handleInputChangeUoM = (index, field, value) => {
    setRows((prevRows) =>
      prevRows.map((row, idx) =>
        idx === index ? { ...row, [field]: value } : row
      )
    );
  };

  return (
    <>
      <div className="table-responsive m-1">
        <table className="mb-2 table">
          <thead>
            <tr>
              <th scope="col">Sr. No.</th>
              <th scope="col">Package no.</th>
              <th scope="col">Physical Qty.</th>
              <th scope="col">UOM</th>
              <th scope="col">Condition</th>
              <th scope="col">Batch No.</th>
              <th scope="col" className="text-nowrap">
                Expiry Date
              </th>
              <th scope="col">Store Location</th>
              <th scope="col">Auditor Obs Class</th>
              <th scope="col">Auditor Obs</th>
              <th scope="col">Client Remarks</th>
              <th scope="col">Senior Remarks</th>
              <th scope="col">Image</th>
              <th scope="col">Image1</th>
              <th scope="col">More Info 1</th>
              <th scope="col">More Info 2</th>
              <th scope="col">More Info 3</th>
              {/* <th scope="col">More Info</th> */}
              <th className="text-center" scope="col">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                {columnOrder.map((field) => (
                  <td key={field}>
                    {field === "physical_qty" ? (
                      <input
                        type="text"
                        className="form-control"
                        value={row[field]}
                        onChange={(e) =>
                          handleInputChange(index, field, e.target.value)
                        }
                      />
                    ) : field === "condition" ? (
                      <select
                        className="form-select"
                        value={row[field]}
                        onChange={(e) =>
                          handleInputChange(index, field, e.target.value)
                        }>
                        <option value="Condition">Condition</option>
                        <option value="Damaged">Damaged</option>
                        <option value="Expired">Expired</option>
                        <option value="Scrapped">Scrapped</option>
                        <option value="Original">Original</option>
                        <option value="Used">Used</option>
                        <option value="Custom">Custom</option>
                      </select>
                    ) : field === "expiry_date" ? (
                      <input
                        type="date"
                        className="form-control"
                        value={row[field]}
                        onChange={(e) =>
                          handleInputChange(index, field, e.target.value)
                        }
                      />
                    ) : field === "auditor_observation_class" ? (
                      <select
                        className="form-select"
                        value={row[field]}
                        onChange={(e) =>
                          handleInputChange(index, field, e.target.value)
                        }>
                        <option value="select">select...</option>
                        <option value="SAP Code Mismatch">
                          SAP Code Mismatch
                        </option>
                        <option value="Description of Material Mismatch">
                          Description of Material Mismatch
                        </option>
                        <option value="Duplicate SAP Code">
                          Duplicate SAP Code
                        </option>
                        <option value="Repaired/Reused material">
                          Repaired/Reused material
                        </option>
                        <option value="UOM Mismatch">UOM Mismatch</option>
                        <option value="Different Brand/makers in one SAP code">
                          Different Brand/makers in one SAP code
                        </option>
                        <option value="Expiry Date not mentioned">
                          Expiry Date not mentioned
                        </option>
                        <option value="Department Mismatch">
                          Department Mismatch
                        </option>
                        <option value="Item Group and sub group mismatch">
                          Item Group and sub group mismatch
                        </option>
                        <option value="Different packaging size">
                          Different packaging size
                        </option>
                        <option value="Some of the details not mentioned">
                          Some of the details not mentioned
                        </option>
                        <option value="Different product compared to others">
                          Different product compared to others
                        </option>
                        <option value="Old aged product">
                          Old aged product
                        </option>
                        <option value="Kit Components">Kit Components</option>
                        <option value="Dismantled products">
                          Dismantled products
                        </option>
                        <option value="Non Useable products">
                          Non Useable products
                        </option>
                        <option value="Others">Others</option>
                      </select>
                    ) : field === "uom_physical" ? (
                      <div>
                        <div key={index}>
                          <select
                            className="form-select"
                            value={row.uom_physical ? row.uom_physical : ""}
                            onChange={(e) =>
                              handleInputChangeUoM(
                                index,
                                "uom_physical",
                                e.target.value
                              )
                            }>
                            <option value="">Select...</option>
                            {uomOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ) : field === "image" ? (
                      <>
                        <div className="m-0 p-0" style={{ display: "flex" }}>
                          <div className="d-flex align-items-center me-2">
                            <label
                              className="d-flex align-items-center"
                              style={{ cursor: "pointer" }}>
                              <i className="bi bi-cloud-arrow-up fs-4"></i>
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "image",
                                    e.target.files[0]
                                  )
                                }
                                style={{ display: "none", cursor: "pointer" }} // Hide the actual file input
                              />
                            </label>
                          </div>
                          <div key={index}>
                            {streams[index] ? ( // Render the camera interface if the stream exists
                              <div className="container-camera-capture">
                                <div className="video-container-table">
                                  <video
                                    id={`video-${index}`} // Assign unique ID to each video
                                    className="video-table"
                                    autoPlay
                                    playsInline
                                  />
                                  <button
                                    onClick={() => handleCapturePhoto(index)}
                                    className="capture-button-table">
                                    <i className="bi bi-camera-fill"></i>{" "}
                                    Capture
                                  </button>
                                  <button
                                    onClick={() => handleCloseCamera(index)}
                                    className="close-button-table">
                                    <i className="bi bi-x-circle"></i> Close
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <button
                                onClick={() => handleOpenCamera(index)}
                                className="border-0 open-camera-image">
                                <i className="bi bi-camera fs-5"></i>
                              </button>
                            )}
                          </div>
                          {row.image && (
                            <div
                              style={{
                                position: "relative",
                                padding: "0px",
                                margin: "0px",
                                marginLeft: "8px",
                              }}>
                              <img
                                src={URL.createObjectURL(row.image)}
                                alt="Captured Preview"
                                style={{
                                  width: "70px",
                                  height: "50px",
                                  borderRadius: "5px",
                                  padding: "0px",
                                }}
                              />
                              <button
                                onClick={() => handleDeleteImage(index)}
                                style={{
                                  position: "absolute",
                                  marginBottom: "75px",
                                  padding: "0px",
                                  backgroundColor: "transparent",
                                  border: "none",
                                  cursor: "pointer",
                                }}>
                                <i
                                  className="bi bi-x fs-4"
                                  style={{ color: "red" }}></i>
                              </button>
                            </div>
                          )}
                        </div>
                      </>
                    ) : field === "image1" ? (
                      <>
                        <div className="" style={{ display: "flex" }}>
                          <div className="d-flex align-items-center me-2">
                            <label
                              className="d-flex align-items-center"
                              style={{ cursor: "pointer" }}>
                              <i className="bi bi-cloud-arrow-up fs-4"></i>
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(e) =>
                                  handleInputChange(
                                    index,
                                    "image1",
                                    e.target.files[0]
                                  )
                                }
                                style={{ display: "none", cursor: "pointer" }} // Hide the actual file input
                              />
                            </label>
                          </div>
                          <div key={index}>
                            {streams1[index] ? (
                              <div className="container-camera-capture">
                                <div className="video-container-table">
                                  <video
                                    id={`video-${index}`} // Ensure this matches the ID used in the function
                                    className="video-table"
                                    autoPlay
                                    playsInline
                                  />
                                  <button
                                    onClick={() => handleCapturePhoto1(index)}
                                    className="capture-button-table">
                                    <i className="bi bi-camera-fill"></i>{" "}
                                    Capture
                                  </button>
                                  <button
                                    onClick={() => handleCloseCamera1(index)}
                                    className="close-button-table">
                                    <i className="bi bi-x-circle"></i> Close
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <button
                                onClick={() => handleOpenCamera1(index)}
                                className="border-0 open-camera-image">
                                <i className="bi bi-camera fs-5"></i>
                              </button>
                            )}
                          </div>
                          {row.image1 && (
                            <div
                              style={{
                                position: "relative",
                                marginLeft: "8px",
                              }}>
                              <img
                                src={URL.createObjectURL(row.image1)}
                                alt="Captured Preview"
                                style={{
                                  width: "70px",
                                  height: "50px",
                                  borderRadius: "5px",
                                }}
                              />
                              <button
                                onClick={() => handleDeleteImage1(index)}
                                style={{
                                  position: "absolute",
                                  // marginLeft: "10px",
                                  marginBottom: "75px",
                                  padding: "0px",
                                  backgroundColor: "transparent",
                                  border: "none",
                                  cursor: "pointer",
                                }}>
                                <i
                                  className="bi bi-x fs-4"
                                  style={{ color: "red" }}></i>
                              </button>
                            </div>
                          )}
                        </div>
                      </>
                    ) : field === "package_no" || field === "batch_no" ? (
                      <input
                        type="text"
                        className="form-control"
                        value={row[field]}
                        onChange={(e) =>
                          handleInputChange(index, field, e.target.value)
                        }></input>
                    ) : (
                      <textarea
                        type="text"
                        className="form-control"
                        value={row[field]}
                        onChange={(e) =>
                          handleInputChange(index, field, e.target.value)
                        }></textarea>
                    )}
                  </td>
                ))}
                <td>
                  <div className="" style={{ display: "flex" }}>
                    <button
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                      onClick={() => deleteRow(index)}
                      style={{
                        border: "none",
                        background: "transparent",
                      }}>
                      <i
                        className="bi bi-trash"
                        style={{
                          fontSize: "18px",
                          color: hoveredIndex === index ? "red" : "black",
                          transition: "color 0.3s, transform 0.3s",
                          transform:
                            hoveredIndex === index ? "scale(1.2)" : "scale(1)",
                        }}
                      />
                    </button>
                    <div>
                      <button
                        onClick={() => copyRow(index)}
                        style={{
                          border: "none",
                          background: "transparent",
                        }}>
                        <i
                          className="bi bi-files"
                          style={{
                            fontSize: "18px",
                            color: "green",
                            transition: "color 0.3s, transform 0.3s",
                          }}
                        />
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="tbl-footer">
        <div className="">
          <strong>Total Physical Qty: </strong>
          {totalPhysicalQty?.toFixed(3)}
        </div>

        <button className="btn btn-add" onClick={addRow}>
          <i className="bi bi-plus"></i>
          <span className="">Add Row</span>
        </button>
      </div>
    </>
  );
};
