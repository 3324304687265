import React from 'react';
import './loader.css'; 

const Loader = ({ show }) => {
  if (!show) return null; 

  return (
    <div className="loader-overlay">
      <div className="loader"></div>
      <p>please wait...</p>
    </div>
  );
};

export default Loader;
