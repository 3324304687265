import React from "react";
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import logo from "../../logo.png";

export const AdminLandingPage = () => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const isActive = (path) => pathname === path;

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <Container>
      {/* Top Navbar */}
      <Navbar expand="md" className="w-100 custom-navbar">
        <div className="w-100 nav-wrapper">
          <Navbar.Brand className="navbar-brand">
            <img src={logo} alt="Triefin" />
          </Navbar.Brand>

          <Navbar.Brand
            className="navbar-brand-name"
            style={{ fontSize: "15px" }}>
            {localStorage.getItem("userRole")}
            <span className="mx-1">
              :{" "}
              {localStorage.getItem("username") &&
                localStorage
                  .getItem("username")
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
            </span>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav">
            <Nav>
              <Nav.Link
                as={Link}
                to="/admin-form"
                className={`me-1 ${
                  isActive("/admin-form") ? "active-link" : ""
                }`}>
                <i className="bi bi-file-earmark-text-fill me-1"></i> Forms
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/admin-master-file"
                className={`me-1 ${
                  isActive("/admin-master-file") ? "active-link" : ""
                }`}>
                <i class="bi bi-filetype-raw"></i> Raw Master
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/variance"
                className={`me-1 ${
                  isActive("/variance") ? "active-link" : ""
                }`}>
                <i className="bi bi-folder-fill me-1"></i> Check Variance
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/admin-report-page"
                className={`me-1 ${
                  isActive("/admin-report-page") ? "active-link" : ""
                }`}>
                <i className="bi bi-bar-chart-fill me-1"></i> Reports
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/team-list"
                className={`me-1 ${
                  isActive("/team-list") ? "active-link" : ""
                }`}>
                <i class="bi bi-person-lines-fill"></i> Team List
              </Nav.Link>
            </Nav>
            <button className="logout-btn" onClick={(e) => handleLogout(e)}>
              <i class="bi bi-box-arrow-right"></i>
            </button>
          </Navbar.Collapse>
        </div>
      </Navbar>

      {/* Main content area */}
      <Row>
        <Col xs={12} className="px-4">
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};
